import ApiAbstract from '../ApiAbstract'

class ServicesApi extends ApiAbstract {
    /**
     * @type {string}
     */
    static baseUri = `/services`

    /**
     * @param api
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static load(api='', callback=null, execCallback=true) {
        return this.getProcess(api, callback, execCallback)
    }

    /**
     * @param data
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static add(data, callback=null, execCallback=true) {
        return this.postProcess('', data, callback, execCallback)
    }

    /**
     * @param uuid
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static remove(uuid, callback=null, execCallback=true) {
        return this.deleteProcess(`/${uuid}`, callback, execCallback)
    }

    /**
     * @param data
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static update(data, callback=null, execCallback=true) {
        return this.putProcess(``, data, callback, execCallback)
    }

}

export default ServicesApi;