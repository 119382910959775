import {COLOR} from '../../services/theme'

export default theme => ({
    root: {
        "& span.MuiFormControlLabel-label": {
            color: COLOR.textLight,
            fontWeight: 500,
            fontSize: 14
        }
    }
})
