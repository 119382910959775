import { withStyles } from '@mui/styles'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import cls from 'classnames'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ProfileMapping from '../../../actions/profile'

import ProfileApi from '../../../api/profile'

import {generateUrl, ROUTE_AUTH, ROUTE_AUTH_MODE, ROUTE_AUTH_MODE_ID, ROUTE_LOADER, ROUTE_NOT_FOUND} from '../../../services/router'
import {isEmail} from '../../../services/utils/checker'
import width from '../../../services/theme/width'

import ComponentAbstract from '../../../components/ComponentAbstract'
import LinkTo from '../../../components/LinkTo'
import BoldSwitch from '../../../components/BoldSwitch'

import styles from '../formStyles'
import Translation from './Translations'

class Login extends ComponentAbstract {

    state = {
        login: '',
        password: '',
        remember: false,
        canConnect: false,
        results: null
    }

    componentWillMount() {
        this.Translation = Translation
        this.props.actions.app.setSection('')
    }

    handleChange = field => event => {
        if(event.keyCode == 13 && this.state.canConnect) return this.handleLogin()

        this.setState({
            [field]: event.target.value,
            canConnect: !!(isEmail(this.state.login) && this.state.password.length >= 5)
        })
    }

    handleLogin = () => {
        this.showLoader(this.label('loggin'))

        ProfileApi.login({
                login: this.state.login,
                password: this.state.password,
                remember: this.state.remember
            },
            this.handleLoginSuccess,
            this.handleLoadError
        )
    }

    handleLoginSuccess = results => {
        this.hideLoader().hideAlert()

        if (results.checker === '2fa') {
            this.props.actions.profile.check2fa(results)
            return this.redirectTo(ROUTE_AUTH, {page: 'check'})
        }

        this.props.actions.profile.autoLogin(results)

        this.redirectTo(ROUTE_LOADER)
    }

    render() {
        const {classes, width} = this.props

        return (
            <Fragment>
                {(width === 'xs' || width === 'sm') ? (
                    <div className={classes.loginLogoContainer}>
                        <img src={'/assets/images/account/login.png'} alt={'login_icon'} className={classes.loginLogo} />
                    </div>
                ) : ''}
                <TextField
                    className={classes.field}
                    label={this.label('email')}
                    margin={'normal'}
                    onChange={this.handleChange('login')}
                    onKeyUp={this.handleChange('login')}
                    value={this.state.login}
                    type={'email'}
                    variant={'outlined'}
                    autoFocus
                />
                <TextField
                    className={classes.field}
                    label={this.label('password')}
                    margin={"normal"}
                    type={"password"}
                    onChange={this.handleChange('password')}
                    onKeyUp={this.handleChange('password')}
                    value={this.state.password}
                    variant={'outlined'}
                />
                <BoldSwitch
                    value={'remember_me'}
                    label={this.label('remember_me')}
                    onChange={this.handleSwitch('remember')}
                    checked={this.state.remember}
                />

                <Grid container className={classes.ctas} spacing={2}>
                    <Grid item className={classes.orderCtaForgot} xs={12} md={5}>
                        <LinkTo to={generateUrl(ROUTE_AUTH_MODE, {page: 'password', mode: 'forgot'})}>
                            <Button
                                className={classes.cta}
                                color={"primary"}
                            >
                                {this.label('forgot_password')}
                            </Button>
                        </LinkTo>
                    </Grid>
                    <Grid item  className={classes.orderCtaLogin} xs={12} md={7}>
                        <Button
                            className={cls(classes.cta, classes.ctaLogin)}
                            color={'primary'}
                            variant={'contained'}
                            disabled={!this.state.canConnect}
                            onClick={this.handleLogin}
                        >
                            {this.label('connect')}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state,
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            profile: bindActionCreators(ProfileMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Login)
