import { withStyles } from '@mui/styles'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import classnames from 'classnames'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import MenuItem from '@mui/material/MenuItem'
import Menu_ from '@mui/material/Menu'

import React, {Fragment, PureComponent} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

//import * as ProfileMapping from '../../actions/profile'

import {generateUrl, ROUTE_LOADER} from '../../services/router'
import {getEnviron, roundD} from '../../services/utils'
import width from '../../services/theme/width'

import Translation from '../../data/Translations'
import Schema from '../../data/Menu'

import LinkTo from '../LinkTo'

import styles from './styles'
import logo from '../../media/logo/logo.png'

class Menu extends PureComponent {
    state = {
        width: null,
        anchorDepositMenu: null,
        displayDepositMenu: false,
        anchorWithdrawalMenu: null,
        displayWithdrawalMenu: false
    }

    mode = null

    render() {
        const { classes, pageId, width, states } = this.props

        const scheme = Schema

        let availableRevenus = 0
        let availableRevenusBtc = 0
        let advancedMode = false
        let environ = getEnviron()

        try {
            advancedMode = states.profile.setup.advancedMode
        } catch (e) {
            //console.log('E-MENU', e)
        }

        const locale = localStorage.getItem('__locale__')

        return (
            <div className={classes.root}>

                <LinkTo to={generateUrl(ROUTE_LOADER)}>
                    <div className={classes.containerTop}>
                        <div className={classes.containerLogo}>
                            <img alt={Translation[locale]['wording']['title']} src={logo} className={classes.logo}/>
                        </div>
                        <div className={classes.containerTitle}>
                            <div className={classes.title}>
                                {Translation[locale]['wording']['title']}
                            </div>
                            <div className={classes.subtitle}>
                                {Translation[locale]['wording']['subtitle']}
                            </div>
                        </div>
                    </div>
                </LinkTo>

                <div className={classes.menuContainer}>
                    {(scheme) ? Object.entries(scheme[locale]['sections']).map(([key, value]) => (
                        <Fragment>
                            {((width === 'xs' || width === 'sm') && value.hideOnMobile
                                || ((width === 'md') && value.hideOnTablet)
                                || ((width === 'lg' || width === 'xl') && value.hideOnDesktop)
                                || (environ === 'dev' && value.hideOnDev)
                                || (environ === 'stg' && value.hideOnStg)
                                || (environ === 'prd' && value.hideOnPrd)) ? '' : (
                                <Fragment>
                                    {(value.isAdvanced && !advancedMode) ? '' : (
                                        <List
                                            component="nav"
                                            subheader={<ListSubheader component="div" className={classes.menuHeader}>{value.title}</ListSubheader>}
                                            key={key}
                                            className={classes.menuSection}
                                        >

                                            {value.content.map(content => (
                                                <Fragment>
                                                    {((width === 'xs' || width === 'sm') && content.hideOnMobile
                                                        || ((width === 'md') && content.hideOnTablet)
                                                        || ((width === 'lg' || width === 'xl') && content.hideOnDesktop)
                                                        || (environ === 'dev' && content.hideOnDev)
                                                        || (environ === 'stg' && content.hideOnStg)
                                                        || (environ === 'prd' && content.hideOnPrd)) ? '' : (
                                                        <Fragment>
                                                            {(content.isAdvanced && !advancedMode) ? '' : (
                                                                <LinkTo to={content.path} key={content.id}>
                                                                    <ListItem
                                                                        className={classnames(
                                                                            classes.menuLink,
                                                                            (content.id === pageId) ? classes.selectedButton : classes.unselectedButton
                                                                        )}
                                                                    >
                                                                        <ListItemIcon className={classes.menuIcon}>
                                                                            <Icon>{content.icon}</Icon>
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary={content.name}
                                                                            disableTypography={true}
                                                                        />
                                                                    </ListItem>
                                                                </LinkTo>
                                                            )}
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </List>
                                        )}
                                </Fragment>
                            )}
                        </Fragment>
                    )) : ''}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states : {
            profile: state.profile
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            //profile: bindActionCreators(ProfileMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Menu)