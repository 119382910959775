import {withStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'

import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'

import * as AppMapping from '../../actions/app'

import width from '../../services/theme/width'

import PublicLayout from '../../components/Layout/Public'
import Logo from '../../components/Logo'

import LoginHelper from './Helper/Login'
import ForgotPasswordHelper from './Helper/ForgotPassword'
import RecoveryPasswordHelper from './Helper/RecoveryPassword'
import Login from './Login'
import G2FA from './Login/g2fa'
import ForgotPassword from './Password/Forgot'
import RecoveryPassword from './Password/Recovery'

import styles from './styles'
import getPath from "../../services/utils/getPath";

export const Auth = props => {
    const { classes, width } = props;
    const match = useParams()

    const page = (match && match.page) ? match.page : 'login'
    const mode = (match && match.mode) ? match.mode : null
    const id = (match && match.id) ? match.id : null

    const locale = getPath('locale', 'search')
    if (locale) localStorage.setItem('__locale__', locale)

    let helper = (<LoginHelper />)
    let form = (<Login />)

    if (page === 'check') form = (<G2FA />)
    else if (page === 'password') {
        if (mode === 'forgot') {
            form = (<ForgotPassword />)
            helper = (<ForgotPasswordHelper />)
        }
        else if (mode === 'recovery') {
            form = (<RecoveryPassword uuid={id} />)
            helper = (<RecoveryPasswordHelper />)
        }
    }

    return (
        <PublicLayout>
            <Grid container>
                {(width === 'xs' || width === 'sm') ? '' : (
                    <Grid item md={5} lg={3} className={classes.helpers}>
                        <Logo />
                        {helper}
                    </Grid>
                )}
                <Grid item xs={12} md={7} lg={9}>
                    <Grid container alignItems={'center'} justifyContent={'center'} className={classes.container}>
                        <Grid item>
                            {form}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </PublicLayout>
    )
}

function mapStateToProps(state) {
    return {
        states: {
            app: state.app
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Auth)