import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as VehiclesMapping from '../../../actions/vehicles'

import VehiclesApi from '../../../api/vehicles'

import ComponentAbstract from '../../../components/ComponentAbstract'
import BoldSwitch from '../../../components/BoldSwitch'
import MenuItemIcon from '../../../components/MenuItemIcon'

import styles from './styles'
import Translation from './Translations'

class Editor extends ComponentAbstract {
    hadTimerServices = false

    state = {
        servicesIsLoaded: false,
        isUpdated: false,
        formValues: {
            label: {
                'FR': null,
                'EN': null,
                'ES': null,
                'DE': null,
                'IT': null,
                'RU': null,
                'CN': null,
                'AR': null,
                'JP': null
            },
            icon: '',
            capacity: 2,
            quantity: 1,
            price: 20.00,
            mode: 'owner',
            services: [],
            category: 'route',
            contact: '',
            description: {
                'FR': null,
                'EN': null,
                'ES': null,
                'DE': null,
                'IT': null,
                'RU': null,
                'CN': null,
                'AR': null,
                'JP': null
            },
            isAvailable: true,
            isElectric: false
        },
        services: []
    }

    componentWillMount() {
        this.Translation = Translation
    }

    loadServices = () => {
        const services = []

        for (let i = 0; i < this.props.states.services.items.length; i++) {
            let labels = JSON.parse(this.props.states.services.items[i].label)

            if (this.props.states.services.items[i].categories.includes('vehicle')
                || this.props.states.services.items[i].categories.includes('all')) {
                services.push({
                    'label': labels[this.getLocale(true)],
                    'value': this.props.states.services.items[i].uuid,
                    'icon': this.props.states.services.items[i].icon
                })
            }
        }

        this.setState({services: services})
    }

    handleChangeValue = field => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.value
            }
        })
    }

    handleFormSwitch = field =>event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.checked
            }
        })
    }

    handleChangeServices = (event, values, mode) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                services: values
            }
        })
    }

    handleChangeLocale = (section, field) => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [field]: event.target.value
                }
            }
        })
    }

    handleForm = () => {
        let item = this.state.formValues;

        if (!item.icon || item.icon.length < 3)
            return this.showSnack(this.label('icon', 'warning'), 'warning')

        this.showLoader(this.label('computing', this.props.mode))

        const services = []
        for (let i = 0; i < this.state.formValues.services.length; i++)
            services.push(this.state.formValues.services[i].value)

        item = {...item, services: [...new Set(services)]}

        if (this.props.mode === 'add') {
            VehiclesApi
                .add(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
        else if (this.props.mode === 'edit') {
            VehiclesApi
                .update(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
    }

    handleSuccess = (mode) => {
        this.showSnack(this.label(mode, 'success'), 'success')
        this.props.actions.vehicles.load();
        this.back()
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.vehicles.current
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        const services = []
        const selectedServices = this.props.states.vehicles.current.services
        const allServices = this.props.states.services.items
        const locale = this.getLocale(true);

        for (let i = 0; i < selectedServices.length; i++) {
            for (let j = 0; j < allServices.length; j++) {
                if (selectedServices[i] === allServices[j].uuid) {
                    let labels = JSON.parse(allServices[j].label);
                    services.push({
                        label: labels[locale],
                        value: allServices[j].uuid,
                        icon: allServices[j].icon
                    })
                    break;
                }
            }
        }

        this.setState({
            formValues: {
                ...this.state.formValues,
                description: JSON.parse(this.props.states.vehicles.current.description),
                label: JSON.parse(this.props.states.vehicles.current.label),
                services: services
            }
        })
    }

    servicesIsLoaded = () => {
        if (!this.hadTimerServices) {
            this.hadTimerServices = true
            if (this.props.states.app.loading.services.items !== 1)
                setTimeout(this.waitForServices.bind(this), 1000)
            else this.loadServices()
        }

        return this
    }

    waitForServices = () => {
        if (this.props.states.app.loading.services.items !== 1)
            return setTimeout(this.waitForServices.bind(this), 1000)

        this.loadServices()
    }

    render() {
        const {classes, mode} = this.props

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        this.servicesIsLoaded()

        const locales = this.label('locales', 'locales')
        const categories = this.label('list', 'categories')
        const modes = this.label('list', 'modes')

        return (
            <div className={classes.formContainer}>
                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('name', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    className={classes.field}
                                    margin={'normal'}
                                    value={this.state.formValues.label[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                    onChange={this.handleChangeLocale('label', key)}
                                    onKeyUp={this.handleChangeLocale('label', key)}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <TextField
                    className={classes.field}
                    label={this.label('icon', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.icon}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('icon')}
                    onKeyUp={this.handleChangeValue('icon')}
                />

                <div style={{textAlign: 'right', width: '100%'}}>
                    <small>
                        <a href={this.label('icon_link_', 'form')} target={'_blank'} style={{marginBottom: 10}}>
                            {this.label('icon_link', 'form')}
                            <Icon style={{fontSize: 15, paddingTop: 2}}>launch</Icon>
                        </a>
                    </small>
                </div>

                <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.field}
                            label={this.label('capacity', 'form')}
                            margin={'normal'}
                            value={this.state.formValues.capacity}
                            type={'number'}
                            variant={'outlined'}
                            onChange={this.handleChangeValue('capacity')}
                            onKeyUp={this.handleChangeValue('capacity')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.field}
                            label={this.label('quantity', 'form')}
                            margin={'normal'}
                            value={this.state.formValues.quantity}
                            type={'number'}
                            variant={'outlined'}
                            onChange={this.handleChangeValue('quantity')}
                            onKeyUp={this.handleChangeValue('quantity')}
                        />
                    </Grid>
                </Grid>

                <TextField
                    className={classes.field}
                    label={this.label('price', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.price}
                    type={'number'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('price')}
                    onKeyUp={this.handleChangeValue('price')}
                />

                <FormControl className={classes.select}>
                    <Select
                        value={this.state.formValues.category}
                        onChange={this.handleChangeValue('category')}
                        variant={'outlined'}
                    >
                        {categories && Object.entries(categories).map(([key, value]) => {
                            return (
                                <MenuItem value={key}>
                                    <MenuItemIcon
                                        icon={this.label(key, 'category_icon')}
                                        label={value}
                                    />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <FormControl className={classes.select}>
                    <Select
                        value={this.state.formValues.mode}
                        onChange={this.handleChangeValue('mode')}
                        variant={'outlined'}
                    >
                        {modes && Object.entries(modes).map(([key, value]) => {
                            return (
                                <MenuItem value={key}>
                                    <MenuItemIcon
                                        icon={this.label(key, 'mode_icon')}
                                        label={value}
                                    />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.services}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeServices}
                    value={this.state.formValues.services}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('services', 'form')}  />
                    )}
                />

                <TextField
                    className={classes.field}
                    label={this.label('contact', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.contact}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('contact')}
                    onKeyUp={this.handleChangeValue('contact')}
                />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('description', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('description', key)}
                                    onKeyUp={this.handleChangeLocale('description', key)}
                                    className={classes.field}
                                    multiline
                                    value={this.state.formValues.description[key]}
                                    rows={4}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Stack direction="column" spacing={1}>
                    <BoldSwitch
                        value={'isElectric'}
                        label={this.label('is_electric', 'form')}
                        onChange={this.handleFormSwitch('isElectric')}
                        checked={this.state.formValues.isElectric}
                    />

                    <BoldSwitch
                        value={'isAvailable'}
                        label={this.label('is_available', 'form')}
                        onChange={this.handleFormSwitch('isAvailable')}
                        checked={this.state.formValues.isAvailable}
                    />
                </Stack>

                <Button variant={'contained'} onClick={this.handleForm} color={'primary'} className={classes.cta}>
                    {this.label(mode)}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            vehicles: bindActionCreators(VehiclesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
