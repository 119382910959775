import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

import cls from 'classnames'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import ProfileApi from '../../../../api/profile'

import * as AppMapping from '../../../../actions/app'

import {ROUTE_AUTH} from '../../../../services/router'
import width from '../../../../services/theme/width'

import ComponentAbstract from '../../../../components/ComponentAbstract'
import SectionTitle from '../../../../components/SectionTitle'

import styles from '../../formStyles'
import Translation from '../Translations'

class PasswordRecovery extends ComponentAbstract {

    state = {
        password: '',
        canConnect: false,
        results: null,
    }

    componentWillMount() {
        this.Translation = Translation
        this.props.actions.app.setSection('')
    }

    handleChange = field => event => {
        if(event.keyCode == 13 && this.state.canConnect) return this.handleRecovery()

        this.setState({
            [field]: event.target.value,
            canConnect: !!(this.state.password.length >= 5)
        })
    }

    handleRecovery = () => {
        this.showLoader(this.label('update_password', 'action'))

        ProfileApi.passwordUpdate(
            {
                code: this.props.uuid,
                password: this.state.password
            },
            this.handleRecoverySuccess,
            this.handleLoadError
        )
    }

    handleRecoverySuccess = (results) => {
        this.hideAlert()
            .hideLoader()
            .showSnack(this.label('update_password_success'), 'success', 10000)
            .redirectTo(ROUTE_AUTH, {page: 'login'})
    }

    render() {
        const {classes} = this.props;

        return (
            <Grid container alignItems={'center'} justify={'center'} className={classes.container}>
                <Grid item>
                    <SectionTitle title={this.label('title')} size={'large'}/>
                    <TextField
                        margin={"normal"}
                        className={classes.field}
                        label={this.label('choose_new_password')}
                        onChange={this.handleChange('password')}
                        onKeyUp={this.handleChange('password')}
                        value={this.state.password}
                        variant={'outlined'}
                        autoFocus
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <IconButton onClick={this.switchVisibility('password_visible')}>
                                        {this.state.password_visible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        type={this.state.password_visible ? 'text' : 'password'}
                    />

                    <Grid container className={classes.ctas} spacing={2}>
                        <Grid item  className={classes.orderCtaLogin} xs={12}>
                            <Button
                                className={cls(classes.cta, classes.ctaLogin)}
                                color={'primary'}
                                variant={'contained'}
                                disabled={!this.state.canConnect}
                                onClick={this.handleRecovery}
                            >
                                {this.label('continue')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: {
            app: state.app
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(PasswordRecovery)
