import { makeStyles } from '@mui/styles'
import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

import Translation from './Translations.json'

export const Login = props => {
    const classes = useStyles();

    const locale = (props.locale)
        ? props.locale
        : localStorage.getItem('__locale__')

    return (
        <div className={classes.containerInfo}>
            <span className={classes.subtitle}>TTT-ID</span>
            <p className={classes.text}>{Translation[locale].login.description}</p>
        </div>
    )
}

export default Login
