import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ToursMapping from '../../../actions/tours'

import ToursApi from '../../../api/tours'

import ComponentAbstract from '../../../components/ComponentAbstract'
import BoldSwitch from '../../../components/BoldSwitch'

import styles from './styles'
import Translation from './Translations'

class Editor extends ComponentAbstract {
    hadTimerServices = false
    hadTimerVehicles = false
    hadTimerHotels = false
    hadTimerCategories = null
    hadTimerActivities = null
    locale = 'FR'

    state = {
        isUpdated: false,
        formValues: {
            activities: [],
            categories: [],
            vehicles: [],
            hotels: [],
            services: [],
            label: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            url: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            route: {
                'FR': 'circuit',
                'EN': 'tour',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            description: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            isAvailable: true
        },
        categories: [],
        vehicles: [],
        hotels: [],
        services: [],
        activities: []
    }

    componentWillMount() {
        this.Translation = Translation
        this.locale = this.getLocale(true)
    }

    loadItems = itemType => {
        const items = []

        for (let i = 0; i < this.props.states[itemType].items.length; i++) {
            let labels = JSON.parse(this.props.states[itemType].items[i].label)

            let item = {
                'label': labels[this.locale],
                'value': this.props.states[itemType].items[i].uuid
            }

            if (itemType === 'services' || itemType === 'categories') {
                if (this.props.states[itemType].items[i].categories.includes('tours')
                    || this.props.states[itemType].items[i].categories.includes('all')) {
                    item = {...item, 'icon': this.props.states[itemType].items[i].icon}
                }
            }
            else if (itemType === 'hotels')
                item = { ...item, 'icon': this.label(this.props.states[itemType].items[i].category, `${itemType}_icons`) }
            else if (itemType === 'vehicles')
                item = {...item, 'icon': this.props.states[itemType].items[i].icon }
            else if (itemType === 'activities')
                item = {...item, 'icon': 'surfing' }

            if (item.icon) items.push(item)
        }

        this.setState({[itemType]: items})
    }

    handleChangeTags = field => (event, values, mode) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: values
            }
        })
    }

    handleFormSwitch = field =>event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.checked
            }
        })
    }

    handleChangeLocale = (section, field) => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [field]: event.target.value
                }
            }
        })
    }

    handleForm = () => {
        this.showLoader(this.label('computing', this.props.mode))

        const arr = ['services', 'vehicles', 'hotels', 'categories', 'activities']
        let item = this.state.formValues
        item = {
            ...item,
            services: [], vehicles: [], hotels: [], categories: [], activities: []
        }

        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < this.state.formValues[arr[i]].length; j++)
                item[arr[i]].push(this.state.formValues[arr[i]][j].value)

            item = {
                ...item,
                [arr[i]]: [...new Set(item[arr[i]])]
            }
        }

        if (this.props.mode === 'add') {
            ToursApi
                .add(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
        else if (this.props.mode === 'edit') {
            ToursApi
                .update(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
    }

    handleSuccess = (mode) => {
        this.showSnack(this.label(mode, 'success'), 'success')
        this.props.actions.tours.load()
        this.back()
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.tours.current
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                label: JSON.parse(this.props.states.tours.current.label),
                description: JSON.parse(this.props.states.tours.current.description),
                url: JSON.parse(this.props.states.tours.current.url),
                route: JSON.parse(this.props.states.tours.current.route),
                categories: this.loadTransform('categories'),
                vehicles: this.loadTransform('vehicles'),
                services: this.loadTransform('services'),
                hotels: this.loadTransform('hotels'),
                activities: this.loadTransform('activities')
            }
        })
    }

    loadTransform = (form) => {
        const result = []
        const selectedForm = this.props.states.tours.current[form] || []
        const all = this.props.states[form].items || []

        for (let i = 0; i < selectedForm.length; i++) {
            for (let j = 0; j < all.length; j++) {
                if (selectedForm[i] === all[j].uuid) {
                    if (form === 'places') {
                        result.push({
                            label: all[j].label,
                            value: all[j].uuid
                        })
                    } else {
                        let labels = JSON.parse(all[j].label)
                        result.push({
                            label: labels[this.locale],
                            value: all[j].uuid
                        })
                    }
                    break
                }
            }
        }

        return result
    }

    categoriesIsLoaded = () => {
        if (!this.hadTimerCategories) {
            this.hadTimerCategories = true
            if (this.props.states.app.loading.categories.items !== 1)
                setTimeout(this.waitForCategories.bind(this), 1000)
            else this.loadItems('categories')
        }

        return this
    }

    waitForCategories = () => {
        if (this.props.states.app.loading.categories.items !== 1)
            return setTimeout(this.waitForCategories.bind(this), 1000)

        this.loadItems('categories')
    }

    servicesIsLoaded = () => {
        if (!this.hadTimerServices) {
            this.hadTimerServices = true
            if (this.props.states.app.loading.services.items !== 1)
                setTimeout(this.waitForServices.bind(this), 1000)
            else this.loadItems('services')
        }

        return this
    }

    waitForServices = () => {
        if (this.props.states.app.loading.services.items !== 1)
            return setTimeout(this.waitForServices.bind(this), 1000)

        this.loadItems('services')
    }

    vehiclesIsLoaded = () => {
        if (!this.hadTimerVehicles) {
            this.hadTimerVehicles = true
            if (this.props.states.app.loading.vehicles.items !== 1)
                setTimeout(this.waitForVehicles.bind(this), 1000)
            else this.loadItems('vehicles')
        }

        return this
    }

    waitForVehicles = () => {
        if (this.props.states.app.loading.vehicles.items !== 1)
            return setTimeout(this.waitForVehicles.bind(this), 1000)

        this.loadItems('vehicles')
    }

    hotelsIsLoaded = () => {
        if (!this.hadTimerHotels) {
            this.hadTimerHotels = true
            if (this.props.states.app.loading.hotels.items !== 1)
                setTimeout(this.waitForHotels.bind(this), 1000)
            else this.loadItems('hotels')
        }

        return this
    }

    waitForHotels = () => {
        if (this.props.states.app.loading.hotels.items !== 1)
            return setTimeout(this.waitForHotels.bind(this), 1000)

        this.loadItems('hotels')
    }

    activitiesIsLoaded = () => {
        if (!this.hadTimerActivities) {
            this.hadTimerActivities = true
            if (this.props.states.app.loading.activities.items !== 1)
                setTimeout(this.waitForActivities.bind(this), 1000)
            else this.loadItems('activities')
        }

        return this
    }

    waitForActivities = () => {
        if (this.props.states.app.loading.activities.items !== 1)
            return setTimeout(this.waitForActivities.bind(this), 1000)

        this.loadItems('activities')
    }

    render() {
        const {classes, mode} = this.props

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        this
            .servicesIsLoaded()
            .categoriesIsLoaded()
            .vehiclesIsLoaded()
            .hotelsIsLoaded()
            .activitiesIsLoaded()

        const locales = this.label('locales', 'locales')

        return (
            <div className={classes.formContainer}>
                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('name', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    className={classes.field}
                                    value={this.state.formValues.label[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                    onChange={this.handleChangeLocale('label', key)}
                                    onKeyUp={this.handleChangeLocale('label', key)}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('description', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('description', key)}
                                    onKeyUp={this.handleChangeLocale('description', key)}
                                    className={classes.field}
                                    value={this.state.formValues.description[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.categories}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('categories')}
                    value={this.state.formValues.categories}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('categories', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.activities}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('activities')}
                    value={this.state.formValues.activities}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('activities', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.services}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('services')}
                    value={this.state.formValues.services}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('services', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.vehicles}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('vehicles')}
                    value={this.state.formValues.vehicles}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('vehicles', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.hotels}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('hotels')}
                    value={this.state.formValues.hotels}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('hotels', 'form')}  />
                    )}
                />

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('url', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('url', key)}
                                    onKeyUp={this.handleChangeLocale('url', key)}
                                    className={classes.field}
                                    value={this.state.formValues.url[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('route', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('route', key)}
                                    onKeyUp={this.handleChangeLocale('route', key)}
                                    className={classes.field}
                                    value={this.state.formValues.route[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Stack direction="column" spacing={1}>
                    <BoldSwitch
                        value={'isAvailable'}
                        label={this.label('is_available', 'form')}
                        onChange={this.handleFormSwitch('isAvailable')}
                        checked={this.state.formValues.isAvailable}
                    />
                </Stack>

                <Button variant={'contained'} onClick={this.handleForm} color={'primary'} className={classes.cta}>
                    {this.label(mode)}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            tours: bindActionCreators(ToursMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
