import { withStyles } from '@mui/styles'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as PlacesMapping from '../../../actions/places'

import ComponentAbstract from '../../../components/ComponentAbstract'
import Map from '../../../components/Map'
import ArrayContainer from '../../../components/ArrayContainer'
import RowInfo from '../../../components/RowInfo'

import styles from './styles'
import Translation from './Translations'

class Viewer extends ComponentAbstract {
    state = {
        isUpdated: false,
        item: {}
    }

    componentWillMount() {
        this.Translation = Translation
    }

    handleFillItem = () => {
        setTimeout(this.fillItem.bind(this), 200)
    }

    fillItem = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            item: this.props.states.places.current
        })
    }
    render() {
        const {classes, mode} = this.props

        if (mode === 'view' && !this.state.isUpdated) this.handleFillItem()

        return (
            <div className={classes.container}>
                <h1><strong>{this.state.item.label}</strong></h1>
                <Map
                    center={{
                        coordinate: {
                            lat: this.state.item.latitude ?? 0,
                            lng: this.state.item.longitude ?? 0
                        }
                    }}
                    marker={{
                        coordinate: {
                            lat: this.state.item.latitude ?? 0,
                            lng: this.state.item.longitude ?? 0
                        }
                    }}
                    zoom={9}
                />
                <ArrayContainer spacing={0} marginTop={20}>
                    <RowInfo
                        label={this.label('category')}
                        value={this.label(this.state.item.category ?? 'none', 'category_text')}
                        forceValueDisplay
                    />
                    <RowInfo
                        label={this.label('compass')}
                        value={this.label(this.state.item.compass ?? 'none', 'localisation_text')}
                        forceValueDisplay
                    />
                    <RowInfo
                        label={this.label('latitude')}
                        value={this.state.item.latitude ?? '-'}
                        forceValueDisplay
                    />
                    <RowInfo
                        label={this.label('longitude')}
                        value={this.state.item.longitude ?? '-'}
                        forceValueDisplay
                        isLast
                    />
                </ArrayContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            places: bindActionCreators(PlacesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Viewer)
