import { withStyles } from '@mui/styles'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'

import React, {Component} from 'react'

import Translation from '../../data/Translations'
import styles from './styles'

class SimpleAlert extends Component {
    state = {}

    transition = props => {
        return <Slide direction="up" {...props} />
    }

    render() {
        const { config, handleClose, classes } = this.props

        const locale = localStorage.getItem('__locale__')

        return (
            <Dialog
                open={config.display}
                TransitionComponent={this.transition}
            >
                <DialogTitle>{config.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.description}>
                        {config.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {Translation[locale]['label']['ok']}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(SimpleAlert)
