import {LOAD_PROFILE_SUCCESS, LOGOUT_PROFILE_SUCCESS, AUTO_LOGIN, LOGIN_2FA, NONE_} from './types'
import ProfileApi from '../../api/profile'

import {getEnviron} from '../../services/utils'
import {Cookie} from '../../services/utils/cookies'

import Constants from '../../data/Constants.json'

export function loadProfileSuccess(profile) {
    return {
        type: LOAD_PROFILE_SUCCESS,
        data: profile
    }
}

export function autoLogin(options) {
    let returnType = NONE_
    const environ = getEnviron()
    const domain = Constants[environ].cookies.domain

    localStorage.setItem('__token__', 'public')
    localStorage.setItem('__isAuth__', false)
    localStorage.setItem('__rememberMe__', false)
    localStorage.setItem('__user__', 'unknown_user')

    Cookie.erase('crossToken', domain, 'public')

    if (options
        && options.isAuth
        && options.token && (options.token !== null && options.token !== 'public')) {
        returnType = AUTO_LOGIN

        localStorage.setItem('__token__', options.token)
        localStorage.setItem('__user__', options.uuid)
        localStorage.setItem('__isAuth__', true)
        localStorage.setItem('__rememberMe__', options.rememberMe)
        localStorage.setItem('__locale__', options.setup.language.toLowerCase())

        Cookie.set('crossToken', options.token, domain, 7)
    }

    return {
        type: returnType,
        data: options || {}
    }
}

export function check2fa(options) {
    let returnType = NONE_

    localStorage.setItem('__token__', 'public')
    localStorage.setItem('__isAuth__', false)

    if (options
        && options.token
        && (options.token !== null && options.token !== 'public')) {
        returnType = LOGIN_2FA
        localStorage.setItem('__token__', options.token)
    }

    return {
        type: returnType,
        data: options || {}
    }
}

export function logoutSuccess() {
    const environ = getEnviron()
    const domain = Constants[environ].cookies.domain

    localStorage.setItem('__token__', 'public')
    localStorage.setItem('__isAuth__', false)
    localStorage.setItem('__rememberMe__', false)
    localStorage.setItem('__user__', 'unknown_user')
    localStorage.setItem('__locale__', 'fr')

    Cookie.erase('crossToken', domain, 'public')

    return {
        type: LOGOUT_PROFILE_SUCCESS,
        data: {
            token: 'public',
            isAuth: false
        }
    }
}

export function loadProfile() {
    return function(dispatch, getState) {
        return ProfileApi.getProfile()
            .then(profile => { dispatch(loadProfileSuccess(profile)) })
            .catch(error => { throw(error) })
    }
}
