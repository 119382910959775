import { makeStyles } from '@mui/styles'
import cls from 'classnames'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const SectionTitle = props => {
    const classes = useStyles();

    let rootClasses = [classes.root]

    if (props.size && props.size === 'small') rootClasses.push(classes.small)
    else if (!props.size || (props.size && props.size === 'medium')) rootClasses.push(classes.medium)
    else if (props.size && props.size === 'large') rootClasses.push(classes.large)

    if (props.className) rootClasses.push(props.className)

    return (<div className={cls(rootClasses)}>{props.title}</div>)
}

export default SectionTitle
