import React from 'react'

import {generateUrl, ROUTE_AUTH} from '../services/router'

import Translations from '../data/Translations'

class ComponentAbstract extends React.Component {
    Translation = {}
    state = {}

    handleChangeLocale = event => { this.setState({locale: event.target.value}) }
    handleSwitch = field => event => { this.setState({[field]:event.target.checked}) }
    switchVisibility = elem => event => { this.setState({ [elem]: !this.state[elem] }) }
    dialVisibility = () => { this.setState({ dial: {open: !this.state.dial.open } }) }

    handleClosePanel = panelName => {
        panelName = (panelName.target) ? panelName.target.id : panelName
        this.setState({
            panel:{
                ...this.state.panel,
                [panelName]: {...this.state.panel[panelName], title: '', display: false}}})

        return this
    }

    handleOpenPanel = (panelName, title='', direction='right')  => {
        this.setState({
            panel:{
                ...this.state.panel,
                [panelName]: {direction: direction, title: title, display: true}}})
    }

    checkPanelsCloseHandler = (panels = []) => {
        for (let i = 0; i < panels.length; i++) {
            if (this.state.panel[panels[i]].display) {
                this.handleClosePanel(panels[i])
            }
        }
    }

    handleClosePanels = (panels = []) => {
        for (let i = 0; i < panels.length; i++) {
            if (this.state.panel[panels[i]].display) {
                window.history.back()
                const self = this
                setTimeout(function () {
                    self.handleClosePanel(panels[i])
                }, 100)
            }
        }
    }

    handleOpenDialog = (dialogName, title='', maxWidth='sm')  => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                [dialogName]: {maxWidth: maxWidth, display: true, title: title}}})
    }

    handleCloseDialog = (dialogName) => event => {
        this.setState({
            dialog:{
                ...this.state.dialog,
                [dialogName]: {display: false, title: ''}}})
    }

    handleCloseDialogNoEvent = (dialogName) => {
        this.setState({
            dialog:{
                ...this.state.dialog,
                [dialogName]: {display: false, title: ''}}})
    }

    checkDialogsCloseHandler = (dialogs = []) => {
        for (let i = 0; i < dialogs.length; i++) {
            if (this.state.dialog[dialogs[i]].display) {
                this.handleCloseDialogNoEvent(dialogs[i])
            }
        }
    }

    handleCloseDialogs = (dialogs = []) => {
        for (let i = 0; i < dialogs.length; i++) {
            if (this.state.dialog[dialogs[i]].display) {
                window.history.back()
                const self = this
                setTimeout(function () {
                    self.handleCloseDialogNoEvent(dialogs[i])
                }, 100)
            }
        }
    }

    showAlert = (message, title='') => {
        const self = this
        setTimeout(function () { self.props.actions.app.showAlert(message, title) }, 100)

        return this
    }

    hideAlert = (callback = null) => {
        const self = this
        setTimeout(function () { self.props.actions.app.hideAlert(callback) }, 100)

        return this
    }

    showSnack = (message, mode='info', duration=5000, callback=null, callbackParameters={}) => {
        const self = this
        setTimeout(function () {
            if (self && self.props.actions && self.props.actions.app) {
                self.props.actions.app.showSnack(message, mode, duration)
                if (callback && (typeof(callback) === 'function')) callback(callbackParameters)
            }
        }, 100)

        return this
    }

    hideSnack = () => {
        const self = this
        setTimeout(function () {
            if (self && self.props.actions && self.props.actions.app)
                self.props.actions.app.hideSnack()
        }, 100)

        return this
    }

    showLoader = (message, callback = null) => {
        const self = this
        setTimeout(function () {
            if (self && self.props.actions && self.props.actions.app)
                self.props.actions.app.showLoader(message, callback)
        }, 100)

        return this
    }

    hideLoader = (callback, callbackParameters) => {
        const self = this
        setTimeout(function () {
            if (self && self.props.actions && self.props.actions.app) {
                self.props.actions.app.hideLoader()
                if (callback && (typeof(callback) === 'function')) callback(callbackParameters)
            }
        }, 100)

        return this
    }

    handleLoadError = (result, callback=null, callbackParameters={}, mode='error', duration=10000) => {
        this.hideLoader()

        let statusCode = (result && result.request && result.request.status) ? result.request.status : 0
        statusCode = (result && result.data && result.data.request && result.data.request.status) ? result.data.request.status : statusCode

        this.showSnack(
            this.getAlert(statusCode),mode,duration,callback,callbackParameters)
    }

    getRecord = (objectType, keyName, inCollection='items', getBy='uuid') => {
        if (this.props.states[objectType][inCollection]) {
            for (let i=0; i < this.props.states[objectType][inCollection].length; i++) {
                if (this.props.states[objectType][inCollection][i][getBy] === keyName)
                    return this.props.states[objectType][inCollection][i]
            }
        }

        return null
    }

    getRecordDetail = (objectType, keyName, inCollection='items', index = 'node', getBy='iso') => {
        if (this.props.states[objectType][inCollection]) {
            for (let i=0; i < this.props.states[objectType][inCollection].length; i++) {
                if (this.props.states[objectType][inCollection][i][index][getBy] === keyName)
                    return this.props.states[objectType][inCollection][i]
            }
        }

        return null
    }

    getSearchRecordDetail = (objectType, keyName, inCollection='items', index = 'node', getBy=['iso']) => {
        const result = []

        if (this.props.states[objectType][inCollection]) {
            for (let i=0; i < this.props.states[objectType][inCollection].length; i++) {
                for(let j=0; j < getBy.length; j++) {
                    if (this.props.states[objectType][inCollection][i][index][getBy[j]].toLowerCase().includes(keyName.toLowerCase())) {
                        result.push(this.props.states[objectType][inCollection][i])
                        break;
                    }
                }
            }
        }

        return result
    }

    label = (label, section='label') => {
        let message = null

        const locale = localStorage.getItem('__locale__')

        try {
            if (this.Translation && this.Translation[locale]
                && this.Translation[locale][section]
                && this.Translation[locale][section][label])
                message = this.Translation[locale][section][label]
            else if (Translations && Translations[locale]
                && Translations[locale][section]
                && Translations[locale][section][label])
                message = Translations[locale][section][label]
        }
        catch(error) { message = 'wording_error' }

        return message
    }

    getLocale = (toUpper = false) => {
        return (toUpper)
            ? localStorage.getItem('__locale__').toUpperCase()
            : localStorage.getItem('__locale__').toLowerCase()
    }

    getAlert = (statusCode=418, errorCode = null) => {
        const message = (errorCode !== null)
            ? this.label(errorCode, 'alert')
            : this.label(statusCode, 'alert')

        try { return (message) ? message : 'unexpected_error' }
        catch(error) { return 'unexpected_throwed_error' }
    }

    redirectTo = (route, params = {}, mode = 'href') => {
        window.location[mode] = generateUrl(route, params)
        return this
    }

    back = () => {
        window.history.back()
        return this
    }

    logout = () => {
        this.showLoader(this.label('login_out'))
        const self = this
        setTimeout(() => {
            //self.props.actions.app && self.props.actions.app.setLoaders('places', {items: 0})
            self.props.actions.tmp && self.props.actions.tmp.unload()
            self.props.actions.profile && self.props.actions.profile.logoutSuccess()
            self.hideLoader(() => {this.redirectTo(ROUTE_AUTH, {page: 'login'})})
        }, 200)
    }
}

export default ComponentAbstract