import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../../actions/app'
import * as ToursMapping from '../../../../actions/tours'
import * as JourneyMapping from '../../../../actions/journey'
import * as TmpMapping from '../../../../actions/tmp'

import {getEnviron} from '../../../../services/utils'

import width from '../../../../services/theme/width'
import ComponentAbstract from '../../../../components/ComponentAbstract'
import MenuItemIcon from '../../../../components/MenuItemIcon'

import styles from '../styles'
import Translation from './Translations'
import Constants from '../../../../data/Constants.json'

class Editor extends ComponentAbstract {
    hadTimerServices = false
    hadTimerPlaces = false
    hadTimerVisits = false
    hadTimerVehicles = false
    hadTimerRestaurants = false
    hadTimerHotels = false
    hadTimerActivities = false
    timerSave = null

    uuid = null

    state = {
        locale: 'FR',
        isUpdated: false,
        picture: null,
        formValues: {
            selectedFile: null,
            services: [],
            places: [],
            activities: [],
            visits: [],
            vehicles: [],
            restaurants: [],
            hotels: [],
            description: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            label: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            }
        },
        services: [],
        places: [],
        visits: [],
        vehicles: [],
        restaurants: [],
        hotels: [],
        activities: []
    }

    componentWillMount() {
        this.Translation = Translation
        this.setState({locale: this.getLocale(true)})
        this.timerSave = setInterval(this.saveForm.bind(this), 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timerSave)
    }

    saveForm = () => {
        this.props.actions.tmp.populate(this.state.formValues)
    }

    handleChangeLocaleValue = (section, locale) => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [locale]: event.target.value
                }
            }
        })
    }

    handleChangeTags = field => (event, values, mode) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: values
            }
        })
    }

    handleSelectFile = event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                ['selectedFile']: event.target.files[0]
            }
        })
    }

    loadItems = itemType => {
        const items = []

        for (let i = 0; i < this.props.states[itemType].items.length; i++) {
            let labels = JSON.parse(this.props.states[itemType].items[i].label)

            let item = {
                'label': labels[this.state.locale],
                'value': this.props.states[itemType].items[i].uuid
            }

            if (itemType === 'services') {
                if (this.props.states.services.items[i].categories.includes('tours')
                    || this.props.states.services.items[i].categories.includes('all')) {
                    item = {...item, 'icon': this.props.states[itemType].items[i].icon}
                }
            }
            else if (itemType === 'restaurants' || itemType === 'hotels' || itemType === 'visits')
                item = { ...item, 'icon': this.label(this.props.states[itemType].items[i].category, `${itemType}_icons`) }
            else if (itemType === 'vehicles')
                item = {...item, 'icon': this.props.states[itemType].items[i].icon }
            else if (itemType === 'activities')
                item = {...item, 'icon': 'surfing' }

            if (item.icon) items.push(item)
        }

        this.setState({[itemType]: items})
    }

    loadPlaces = () => {
        const places = []

        for (let i = 0; i < this.props.states.places.items.length; i++) {
            places.push({
                'label': this.props.states.places.items[i].label,
                'value': this.props.states.places.items[i].uuid,
                'icon': 'location_on'
            })
        }

        this.setState({places: places})
    }

    servicesIsLoaded = () => {
        if (!this.hadTimerServices) {
            this.hadTimerServices = true
            if (this.props.states.app.loading.services.items !== 1)
                setTimeout(this.waitForServices.bind(this), 1000)
            else this.loadItems('services')
        }

        return this
    }

    waitForServices = () => {
        if (this.props.states.app.loading.services.items !== 1)
            return setTimeout(this.waitForServices.bind(this), 1000)

        this.loadItems('services')
    }

    placesIsLoaded = () => {
        if (!this.hadTimerPlaces) {
            this.hadTimerPlaces = true
            if (this.props.states.app.loading.places.items !== 1)
                setTimeout(this.waitForPlaces.bind(this), 1000)
            else this.loadPlaces()
        }

        return this
    }

    waitForPlaces = () => {
        if (this.props.states.app.loading.places.items !== 1)
            return setTimeout(this.waitForPlaces.bind(this), 1000)

        this.loadPlaces()
    }

    restaurantsIsLoaded = () => {
        if (!this.hadTimerRestaurants) {
            this.hadTimerRestaurants = true
            if (this.props.states.app.loading.restaurants.items !== 1)
                setTimeout(this.waitForRestaurants.bind(this), 1000)
            else this.loadItems('restaurants')
        }

        return this
    }

    waitForRestaurants = () => {
        if (this.props.states.app.loading.restaurants.items !== 1)
            return setTimeout(this.waitForRestaurants.bind(this), 1000)

        this.loadItems('restaurants')
    }

    hotelsIsLoaded = () => {
        if (!this.hadTimerHotels) {
            this.hadTimerHotels = true
            if (this.props.states.app.loading.hotels.items !== 1)
                setTimeout(this.waitForHotels.bind(this), 1000)
            else this.loadItems('hotels')
        }

        return this
    }

    waitForHotels = () => {
        if (this.props.states.app.loading.hotels.items !== 1)
            return setTimeout(this.waitForHotels.bind(this), 1000)

        this.loadItems('hotels')
    }

    visitsIsLoaded = () => {
        if (!this.hadTimerVisits) {
            this.hadTimerVisits = true
            if (this.props.states.app.loading.visits.items !== 1)
                setTimeout(this.waitForVisits.bind(this), 1000)
            else this.loadItems('visits')
        }

        return this
    }

    waitForVisits = () => {
        if (this.props.states.app.loading.visits.items !== 1)
            return setTimeout(this.waitForVisits.bind(this), 1000)

        this.loadItems('visits')
    }

    vehiclesIsLoaded = () => {
        if (!this.hadTimerVehicles) {
            this.hadTimerVehicles = true
            if (this.props.states.app.loading.vehicles.items !== 1)
                setTimeout(this.waitForVehicles.bind(this), 1000)
            else this.loadItems('vehicles')
        }

        return this
    }

    waitForVehicles = () => {
        if (this.props.states.app.loading.vehicles.items !== 1)
            return setTimeout(this.waitForVehicles.bind(this), 1000)

        this.loadItems('vehicles')
    }

    activitiesIsLoaded = () => {
        if (!this.hadTimerActivities) {
            this.hadTimerActivities = true
            if (this.props.states.app.loading.activities.items !== 1)
                setTimeout(this.waitForActivities.bind(this), 1000)
            else this.loadItems('activities')
        }

        return this
    }

    waitForActivities = () => {
        if (this.props.states.app.loading.activities.items !== 1)
            return setTimeout(this.waitForActivities.bind(this), 1000)

        this.loadItems('activities')
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        const env = getEnviron()
        const base = `${Constants[env].endpoint.protocol}://${Constants[env].endpoint.platform}/${Constants.picturesPath.tours}`
        const picture = `${base}/${this.props.states.tours.current.uuid}/${this.props.states.journey.current.uuid}.jpg` ?? ''

        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.journey.current,
            picture: picture
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                description: JSON.parse(this.props.states.journey.current.description),
                label: JSON.parse(this.props.states.journey.current.label),
                services: this.loadTransform('services'),
                places: this.loadTransform('places'),
                visits: this.loadTransform('visits'),
                vehicles: this.loadTransform('vehicles'),
                restaurants: this.loadTransform('restaurants'),
                hotels: this.loadTransform('hotels'),
                activities: this.loadTransform('activities'),
                selectedFile: null
            }
        })
    }

    loadTransform = (form) => {
        const result = []
        const selectedForm = this.props.states.journey.current[form]
        const all = this.props.states[form].items

        for (let i = 0; i < selectedForm.length; i++) {
            for (let j = 0; j < all.length; j++) {
                if (selectedForm[i] === all[j].uuid) {
                    if (form === 'places') {
                        result.push({
                            label: all[j].label,
                            value: all[j].uuid
                        })
                    } else {
                        let labels = JSON.parse(all[j].label);
                        result.push({
                            label: labels[this.state.locale],
                            value: all[j].uuid
                        })
                    }
                    break;
                }
            }
        }

        return result;
    }

    render() {
        const {classes, mode} = this.props
        const locales = this.label('locales', 'locales')
        const filename = this.state.formValues.selectedFile ?? null

        this
            .servicesIsLoaded()
            .placesIsLoaded()
            .visitsIsLoaded()
            .vehiclesIsLoaded()
            .restaurantsIsLoaded()
            .hotelsIsLoaded()
            .activitiesIsLoaded()

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        return (
            <Fragment>
                <Grid container spacing={0} textAlign={'center'}>
                    <Grid item xs={12}>
                        <FormControl className={classes.select}>
                            <Select
                                value={this.state.locale}
                                onChange={this.handleChangeLocale}
                                variant={'outlined'}
                            >
                                {locales && Object.entries(locales).map(([key, value]) => {
                                    return (
                                        <MenuItem value={key}>
                                            <MenuItemIcon
                                                icon={'flag'}
                                                label={value}
                                            />
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} spacing={0} className={classes.mt10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('title')}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6} className={classes.mt10}>
                        <TextField
                            className={classes.field}
                            value={this.state.formValues.label[this.state.locale]}
                            onChange={this.handleChangeLocaleValue('label', this.state.locale)}
                            onKeyUp={this.handleChangeLocaleValue('label', this.state.locale)}
                            type={'text'}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.p5}>
                        <Autocomplete
                            className={classes.autocomplete}
                            multiple
                            options={this.state.activities}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.label}
                            onChange={this.handleChangeTags('activities')}
                            value={this.state.formValues.activities}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={(<Icon>{option.icon}</Icon>)}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label={this.label('activities', 'select')}  />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems={'stretch'} spacing={0} className={classes.mt10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('description')}</Typography>
                    </Grid>
                    <Grid xs={12} sm={12} className={classes.mt10}>
                        <TextField
                            className={classes.field}
                            value={this.state.formValues.description[this.state.locale]}
                            onChange={this.handleChangeLocaleValue('description', this.state.locale)}
                            onKeyUp={this.handleChangeLocaleValue('description', this.state.locale)}
                            type={'text'}
                            variant={'outlined'}
                            rows={10}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.mt10}>
                        <Grid item xs={12}>
                            <Typography variant="h1">{this.label('tags')}</Typography>
                        </Grid>
                        <Grid container className={classes.p5}>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.services}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('services')}
                                    value={this.state.formValues.services}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('services', 'select')}  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.places}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('places')}
                                    value={this.state.formValues.places}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('places', 'select')}  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.visits}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('visits')}
                                    value={this.state.formValues.visits}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('visits', 'select')}  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.vehicles}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('vehicles')}
                                    value={this.state.formValues.vehicles}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('vehicles', 'select')}  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.restaurants}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('restaurants')}
                                    value={this.state.formValues.restaurants}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('restaurants', 'select')}  />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.p5}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    multiple
                                    options={this.state.hotels}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.handleChangeTags('hotels')}
                                    value={this.state.formValues.hotels}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={(<Icon>{option.icon}</Icon>)}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label={this.label('hotels', 'select')}  />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} spacing={0} className={classes.mtb10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('media')}</Typography>
                    </Grid>
                    <Grid xs={12} sm={7}>
                        <div className={classes.zone}>
                            <input
                                type={"file"}
                                onChange={this.handleSelectFile}
                                name={"files[]"}
                                className={classes.inputField}
                                accept={"image/jpeg, image/png, image/jpg"}
                            />
                            <div className={classes.inputOverlay}>
                                <Icon className={classes.icon}>upload_file</Icon><br />
                                <Button
                                    variant={'contained'}
                                    size={'large'}
                                    color={'primary'}
                                >
                                    {this.label('select_media')}
                                </Button>
                                <p>{this.label('media_max_size')}</p>
                                {(!filename) ? '' : ( <p>{`${this.label('media_filename')}: `}<b>{`${filename.name}`}</b></p> )}
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={4} className={classes.mt10}>
                        <img src={this.state.picture} alt={'photo'} className={classes.picture} />
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state,
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            tours: bindActionCreators(ToursMapping, dispatch),
            journey: bindActionCreators(JourneyMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
