import {POPULATE, UNLOAD} from './types'

export function populate(data, callback=null) {
    return {
        type: POPULATE,
        callback: callback,
        data: data
    }
}

export function unload(callback=null) {
    return {
        type: UNLOAD,
        callback: callback
    }
}
