import {COLOR} from '../../services/theme'

export default {
    loader: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'darkslategray',
        opacity: '0.9',
        zIndex: '9999'
    },
    media: {
        margin: '25% auto',
        color: COLOR.white,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    enable: {
        display: 'block'
    },
    disable: {
        display: 'none'
    },
    blue: {
        color: COLOR.main
    }
}