import theme, {COLOR} from '../../services/theme'

export default {
    helpers: {
        backgroundColor: COLOR.main,
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            width: '100%'
        },
        [theme.breakpoints.only('sm')]: {
            height: 190
        },
        [theme.breakpoints.only('xs')]: {
            height: 220
        }
    },
    container: {
        height: '100%',
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    }
}
