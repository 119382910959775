import { withStyles } from '@mui/styles'
import {DataGrid} from "@mui/x-data-grid"
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../../actions/app'
import * as ToursMapping from '../../../../actions/tours'
import * as JourneyMapping from '../../../../actions/journey'
import * as TmpMapping from '../../../../actions/tmp'

import JourneyApi from '../../../../api/journey'

import width from '../../../../services/theme/width'

import ComponentAbstract from '../../../../components/ComponentAbstract'
import SimpleDialog from '../../../../components/SimpleDialog'
import MenuItemIcon from '../../../../components/MenuItemIcon'
import Tip from '../../../../components/Tip'
import FlexRoot from '../../../../components/FlexRoot'
import Description from '../../../../components/Description'

import Editor from "./editor";

import styles from '../styles'
import Translation from './Translations'

class Journey extends ComponentAbstract {
    uuid = null
    mode = 'add'
    locale = 'FR'
    maxPhotoSize = 1 // Mb

    state = {
        dialog: {
            itemEditor: {display: false, title: ''},
            itemDelete: {display: false, title: ''}
        }
    }

    componentWillMount() {
        this.Translation = Translation
        this.locale = this.getLocale(true)
    }

    handleItem = () => {
        this.mode = 'add'
        this.handleOpenDialog('itemEditor', this.label(this.mode, 'title'), 'md')
    }

    handleCommitStep = () => {
        if (this.props.states.tmp.selectedFile && this.props.states.tmp.selectedFile.size > (this.maxPhotoSize * 1024 * 1024)) {
            return this.showSnack(this.label('too_big'), 'warning')
        }

        this.showLoader(this.label(this.mode, 'computing'))

        const arr = ['services', 'places', 'visits', 'vehicles', 'restaurants', 'hotels', 'activities']
        let item = this.props.states.tmp
        item = {
            ...item,
            selectedFile: null,
            services: [], places: [], visits: [], vehicles: [], restaurants: [], hotels: [], activities: []
        }

        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < this.props.states.tmp[arr[i]].length; j++)
                item[arr[i]].push(this.props.states.tmp[arr[i]][j].value)

            item = {
                ...item,
                [arr[i]]: [...new Set(item[arr[i]])]
            }
        }

        if (this.mode === 'add') {
            JourneyApi
                .add({
                    ...item,
                    tour: this.props.states.tours.current.uuid
                })
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    if (this.props.states.tmp.selectedFile) {
                        JourneyApi
                            .uploadPhoto(
                                result.data.uuid,
                                this.props.states.tours.current.uuid,
                                this.props.states.tmp.selectedFile
                            )
                            .then(result => {
                                if (result.except || result.error)
                                    return this.handleLoadError(result)
                                this.hideLoader(this.handleSuccess)
                            })
                    } else this.hideLoader(this.handleSuccess)
                })
        }
        else if (this.mode === 'edit') {
            JourneyApi
                .update({
                    ...item,
                    step: this.props.states.journey.current.uuid
                })
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    if (this.props.states.tmp.selectedFile) {
                        JourneyApi
                            .uploadPhoto(
                                this.props.states.journey.current.uuid,
                                this.props.states.tours.current.uuid,
                                this.props.states.tmp.selectedFile
                            )
                            .then(result => {
                                if (result.except || result.error)
                                    return this.handleLoadError(result)
                                this.hideLoader(this.handleSuccess)
                            })
                    } else this.hideLoader(this.handleSuccess)
                })
        }
    }

    handleSuccess = ()=> {
        this.showSnack(this.label(this.mode, 'success'), 'success')
        this.props.actions.tmp.unload();
        this.props.actions.journey.load(this.props.states.tours.current.uuid);
        this.handleCloseDialogNoEvent('itemEditor')
    }

    transformLabel = (param) => {
        const labels = JSON.parse(param.row.label)
        const screenWidth = width()

        return (
            <MenuItemIcon
                iconColWidth={(screenWidth === 'xs') ? 2 : 1}
                textColWidth={(screenWidth === 'xs') ? 10 : 11}
                iconBackgroundColor={'bgBody'}
                icon={'format_list_numbered'}
                label={labels[this.locale]}
            />
        )
    }

    handleDeletion = uuid => event => {
        this.mode = 'edit'
        this.uuid = uuid
        this.handleOpenDialog('itemDelete', this.label('delete', 'title'), 'md')
    }

    handleDelete = () => {
        this.showLoader(this.label('delete', 'computing'))

        JourneyApi
            .remove(this.uuid)
            .then(result => {
                if (result.except || result.error)
                    return this.handleLoadError(result)
                this.hideLoader(this.handleDeleteSuccess)
            })
    }

    handleDeleteSuccess = () => {
        this.uuid = null
        this.mode = 'list'

        this.showSnack(this.label('delete', 'success'), 'success')
        this.props.actions.journey.load(this.props.states.tours.current.uuid)

        this.handleCloseDialogNoEvent('itemDelete')
    }

    handleEdit = id => event => {
        this.mode = 'edit'
        if (!this.state.dialog.itemEditor.display) {
            let title = this.label('edit', 'title')
            if (this.mode === 'edit' && id !== null) {
                const item = this.getRecord('journey', id)
                if (!item) return
                this.props.actions.journey.setCurrent(item)
                const labels = JSON.parse(item.label)
                title = `${title}: ${labels[this.locale]}`
            }
            this.handleOpenDialog('itemEditor', title, 'md')
        }
    }

    bindActions = (param) => {
        return (
            <Stack direction="row" alignItems="center" justifyItems={"center"} spacing={0}>
                <IconButton color="primary" onClick={this.handleEdit(param.row.uuid)}>
                    <EditIcon />
                </IconButton>
                <IconButton color="danger" onClick={this.handleDeletion(param.row.uuid)}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        )
    }

    render() {
        const {classes,states} = this.props

        const rows = states.journey.items ?? []
        const columns = [
            { field: 'label', headerName: this.label('name', 'column'), flex: 1, renderCell: this.transformLabel },
            { field: 'actions', headerName: this.label('actions', 'column'), width: 100, renderCell: this.bindActions}
        ]

        return (
            <Fragment>
                <SimpleDialog
                    config={this.state.dialog.itemDelete}
                    handleClose={this.handleCloseDialog('itemDelete')}
                    handleSuccess={this.handleDelete}
                    labelValidation={this.label('validate_delete')}
                    canApply
                >
                    <Description text={this.label('delete')} />
                </SimpleDialog>
                <SimpleDialog
                    config={this.state.dialog.itemEditor}
                    handleClose={this.handleCloseDialog('itemEditor')}
                    handleSuccess={this.handleCommitStep}
                    labelValidation={this.label('save')}
                    canApply
                >
                    <Editor mode={this.mode} />
                </SimpleDialog>
                <div className={classes.root}>
                    <Grid container spacing={2} textAlign={'center'}>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={this.handleItem}>
                                {this.label('new_step')}
                                &nbsp;
                                <Icon>switch_access_shortcut_add</Icon>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {(rows.length < 1) ? (
                                <div className={classes.emptyContainer}>
                                    <Tip
                                        icon={'ballot'}
                                        label={this.label('empty_list')}
                                    />
                                </div>
                            ) : (
                                <FlexRoot>
                                    <div className={classes.gridContainer}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            getRowId={(row) => row.uuid}
                                            pageSize={100}
                                            rowsPerPageOptions={[100]}
                                        />
                                    </div>
                                </FlexRoot>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            tours: bindActionCreators(ToursMapping, dispatch),
            journey: bindActionCreators(JourneyMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Journey)
