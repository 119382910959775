export default {
    field: {
        width: '100%'
    },
    select: {
        marginTop: '15px !important',
        width: '100%'
    },
    formContainer: {
        width: '100%'
    },
    cta: {
        marginTop: '20px !important',
        width: '100%'
    }
}
