import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const LinkTo = props => (
    <Typography className={props.className}>
        <Link component={RouterLink} {...props}>
            {props.children}
        </Link>
    </Typography>
)

export default LinkTo
