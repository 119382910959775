import theme,{HEADER_HEIGHT, MENU_WIDTH, COLOR, MIN_HEIGHT} from '../../../services/theme'

export default {
    body: {
        fontFamily: `Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif`,
        backgroundColor: COLOR.body,
        display: 'flex',
        flexDirection: 'column',
        minHeight: MIN_HEIGHT,
        paddingTop: HEADER_HEIGHT,
        width: '100vw',
        overflow: 'hidden',
        [theme.breakpoints.up('xl')]: {
            paddingLeft: MENU_WIDTH
        }
    }
}
