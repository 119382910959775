import { makeStyles } from '@mui/styles'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const BLogo = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <img src={`/assets/logo/logo.png`} alt={'ttt-logo'} className={classes.logo} />
                    </Avatar>
                }
                title={<span className={classes.title}>TTT</span>}
            />
        </div>
    )
}

export default BLogo
