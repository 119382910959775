function closest(el, selector) {
    let matchesFn

    // find vendor prefix
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
        if (typeof document.body[fn] === 'function') {
            matchesFn = fn
            return true
        }
        return false
    })

    let parent

    // traverse parents
    while (el) {
        parent = el.parentElement
        if (parent && parent[matchesFn](selector))
            return parent
        el = parent
    }

    return null
}

function recursiveMatches(el, selector) {
    return el.matches(`${selector} *`) || el.classList.contains(selector.substring(1))
}

function select(selector, context = document) {
    return context ? context.querySelector(selector) : null
}

function selectAll(selector, context = document) {
    return context ? Array.from(context.querySelectorAll(selector)) : null
}

/**
 * @param selector {String}: CSS selector must used to find collections
 * @param root {Element}: optional parameter. If provide search task start from this element
 * @returns {Array}: return a collection of element if something find, else an empty array
 */
function getCollection(selector, root) {
    return (selectAll(selector, root) || [])
}

module.exports = {
    closest,
    recursiveMatches,
    select,
    selectAll,
    getCollection
}
