import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as CategoriesMapping from '../../../actions/categories'

import CategoriesApi from '../../../api/categories'

import ComponentAbstract from '../../../components/ComponentAbstract'
import BoldSwitch from '../../../components/BoldSwitch'

import styles from './styles'
import Translation from './Translations'

class Editor extends ComponentAbstract {
    categories = ['all', 'vehicle', 'hotel', 'visit', 'restaurant', 'tours', 'activities']

    state = {
        isUpdated: false,
        formValues: {
            label: {
                'FR': null,
                'EN': null,
                'ES': null,
                'DE': null,
                'IT': null,
                'RU': null,
                'CN': null,
                'AR': null,
                'JP': null
            },
            icon: '',
            categories: [{label: 'Toutes', value: 'all'}],
            description: {
                'FR': null,
                'EN': null,
                'ES': null,
                'DE': null,
                'IT': null,
                'RU': null,
                'CN': null,
                'AR': null,
                'JP': null
            },
            isAvailable: true
        },
        categories: []
    }

    componentWillMount() {
        this.Translation = Translation

        const categories = []
        for (let i = 0; i < this.categories.length; i++) {
            categories.push({
                'label': this.label(this.categories[i], 'categories_text'),
                'value': this.categories[i]
            })
        }

        this.setState({categories: categories})
    }

    handleChangeValue = field => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.value
            }
        })
    }

    handleAvailable = (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                ['isAvailable']: event.target.checked
            }
        })
    }

    handleChangeCategories = (event, values, mode) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                categories: values
            }
        })
    }

    handleChangeLocale = (section, field) => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [field]: event.target.value
                }
            }
        })
    }

    handleForm = () => {
        let item = this.state.formValues;

        if (!item.icon || item.icon.length < 3)
            return this.showSnack(this.label('icon', 'warning'), 'warning')

        this.showLoader(this.label('computing', this.props.mode))

        const categories = []
        for (let i = 0; i < this.state.formValues.categories.length; i++)
            categories.push(this.state.formValues.categories[i].value)

        item = {...item, categories: [...new Set(categories.includes('all') ? ['all'] : categories)]}

        if (this.props.mode === 'add') {
            CategoriesApi
                .add(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
        else if (this.props.mode === 'edit') {
            CategoriesApi
                .update(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
    }

    handleSuccess = (mode) => {
        this.showSnack(this.label(mode, 'success'), 'success')
        this.props.actions.categories.load();
        this.back()
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.categories.current
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        const categories = []
        const cts = this.props.states.categories.current.categories
        for (let i = 0; i < cts.length; i++)
            categories.push({label: this.label(cts[i], 'categories_text'), value: cts[i]})

        this.setState({
            formValues: {
                ...this.state.formValues,
                description: JSON.parse(this.props.states.categories.current.description),
                label: JSON.parse(this.props.states.categories.current.label),
                categories: categories
            }
        })
    }

    render() {
        const {classes, mode} = this.props

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        const locales = this.label('locales', 'locales')

        return (
            <div className={classes.formContainer}>
                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('name', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    className={classes.field}
                                    margin={'normal'}
                                    value={this.state.formValues.label[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                    onChange={this.handleChangeLocale('label', key)}
                                    onKeyUp={this.handleChangeLocale('label', key)}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <TextField
                    className={classes.field}
                    label={this.label('icon', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.icon}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('icon')}
                    onKeyUp={this.handleChangeValue('icon')}
                />

                <div style={{textAlign: 'right', width: '100%'}}>
                    <small>
                        <a href={this.label('icon_link_', 'form')} target={'_blank'} style={{marginBottom: 10}}>
                            {this.label('icon_link', 'form')}
                            <Icon style={{fontSize: 15, paddingTop: 2}}>launch</Icon>
                        </a>
                    </small>
                </div>

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.categories}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeCategories}
                    value={this.state.formValues.categories}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<Icon>settings_suggest</Icon>}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('categories', 'form')}  />
                    )}
                />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('description', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('description', key)}
                                    onKeyUp={this.handleChangeLocale('description', key)}
                                    className={classes.field}
                                    multiline
                                    value={this.state.formValues.description[key]}
                                    rows={4}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <BoldSwitch
                    value={'isAvailable'}
                    label={this.label('is_available', 'form')}
                    onChange={this.handleAvailable}
                    checked={this.state.formValues.isAvailable}
                />

                <Button variant={'contained'} onClick={this.handleForm} color={'primary'} className={classes.cta}>
                    {this.label(mode)}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            categories: bindActionCreators(CategoriesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
