import Loader from './Loader'
import NotFound from './NotFound'
import Auth from './Auth'
import Dashboard from './Dashboard'
import Vehicles from './Vehicles'
import Places from './Places'
import Hotels from './Hotels'
import Services from './Services'
import Categories from './Categories'
import Visits from './Visits'
import Restaurants from './Restaurants'
import Activities from './Activities'
import Tours from './Tours'
import Inns from './Inns'
import Booking from './Booking'

export {
    Loader,
    NotFound,
    Auth,
    Dashboard,
    Vehicles,
    Places,
    Hotels,
    Services,
    Categories,
    Visits,
    Restaurants,
    Activities,
    Tours,
    Inns,
    Booking
}
