import { withStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../actions/app'
import * as ProfileMapping from '../../actions/profile'
import * as TmpMapping from '../../actions/tmp'

import width from '../../services/theme/width'
import {generateUrl, ROUTE_LOADER} from '../../services/router'
import Link from '../LinkTo'

import ComponentAbstract from '../ComponentAbstract'

import Translation from './Translations'
import styles from './styles'
import {COLOR} from '../../services/theme'

import logo from '../../media/logo/logo.png'

class Header extends ComponentAbstract {
    state = {
        width: null,
        anchorCardMenu: null,
        displayCardMenu: false,
    }

    componentWillMount() {
        this.Translation = Translation
    }

    handleMenuVisibility = event => {
        if (!this.props.states.app.menu.open) this.props.actions.app.showMenu()
        else this.props.actions.app.hideMenu()
    }

    handleAccountMenu = item => event => {
        this.handleCloseMenu()

        if (item === 'logout') this.logout()
    }

    handleOpenMenu = event => {
        this.setState({
            anchorCardMenu: event.currentTarget,
            displayCardMenu: true
        })
    }

    handleCloseMenu = () => {
        this.setState({displayCardMenu: false})
    }

    render() {
        const { showMenuButton, classes, width, states, auth } = this.props

        const roles = (states.profile && states.profile.supportedRoles) ? states.profile.supportedRoles : null
        let isAdmin = false;
        let isSuperAdmin = false;

        if (roles) {
            for (let i = 0; roles.length > i; i++) {
                if (roles[i] === 'ROLE_ADMIN') isAdmin = true;
                if (roles[i] === 'ROLE_SUPER_ADMIN') {
                    isAdmin = true
                    isSuperAdmin = true
                }
            }
        }

        let email = ''

        try {
            email = states.profile.email
        } catch (e) {
            //console.log('E-HEADER: ', e)
        }

        return (
            <AppBar position={'fixed'} className={classes.root} >
                <Toolbar className={classes.toolbar}>

                    {showMenuButton && (
                        <IconButton onClick={this.handleMenuVisibility} className={classes.menuButton}>
                            <MenuIcon style={{color: COLOR.white}}/>
                        </IconButton>
                    )}

                    <div className={classes.title}>
                        <Link to={generateUrl(ROUTE_LOADER)}>
                            <img src={logo} alt='TTT' className={classes.logo}/>
                            <span>TTT Admin</span>
                        </Link>
                    </div>

                    {(width === 'xs') ? '' : (
                        <div className={classes.sectionTitle}>
                            {states.app.section.title}
                        </div>
                    )}

                    {auth && (
                        <Fragment>
                            <IconButton onClick={this.handleOpenMenu} className={classes.accountButton}>
                                <AccountCircleIcon style={{color: COLOR.white}} />
                            </IconButton>
                            <Menu
                                anchorEl={this.state.anchorCardMenu}
                                open={this.state.displayCardMenu}
                                onClose={this.handleCloseMenu}
                            >
                                <MenuItem onClick={this.handleAccountMenu('account')}>
                                    <ListItemIcon>
                                        <Icon>perm_identity</Icon>
                                    </ListItemIcon>

                                    <div style={{margin: 0, padding: 0}}>
                                        {this.label('account')}<br />
                                        <small>{email}</small>
                                    </div>

                                </MenuItem>
                                {(isSuperAdmin || isAdmin) ? (
                                    <MenuItem onClick={this.handleAccountMenu('admin')}>
                                        <ListItemIcon>
                                            <Icon>engineering</Icon>
                                        </ListItemIcon>
                                        {this.label('admin')}
                                    </MenuItem>
                                ) : ''}
                                {(isSuperAdmin) ? (
                                    <MenuItem onClick={this.handleAccountMenu('platform51')}>
                                        <ListItemIcon>
                                            <Icon>token</Icon>
                                        </ListItemIcon>
                                        {this.label('platform51')}
                                    </MenuItem>
                                ) : ''}
                                <Divider />
                                <MenuItem onClick={this.handleAccountMenu('logout')}>
                                    <ListItemIcon>
                                        <Icon>logout</Icon>
                                    </ListItemIcon>
                                    {this.label('logout')}
                                </MenuItem>
                            </Menu>
                        </Fragment>
                    )}
                </Toolbar>
            </AppBar>
        )
    }
}

function mapStateToProps(state) {
    return {
        states : {
            app: state.app,
            profile: state.profile,
            tmp: state.tmp
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            profile: bindActionCreators(ProfileMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Header)
