import {COLOR} from '../../../services/theme'

export default theme => ({
    body: {
        overflow: 'hidden',
        backgroundColor: COLOR.white,
        display: 'flex',
        minHeight: `100vh`,
        width: '100vw'
    }
})
