import {COLOR} from '../../services/theme'

export default theme => ({
    root: {
        color: COLOR.textLight,
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 20
    },
    small: {
        fontSize: 14
    },
    medium: {
        fontSize: 18
    },
    large: {
        fontSize: 24
    }
})
