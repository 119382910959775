import {POPULATE, UNLOAD, SET_LAST_UPDATE, SET_CURRENT_HOTEL} from './types'
import HotelsApi from '../../api/hotels'

export function populate(data, callback=null) {
    return {
        type: POPULATE,
        callback: callback,
        data: data
    }
}

export function unload(callback=null) {
    return {
        type: UNLOAD,
        callback: callback
    }
}

export function setLastUpdate(ts, callback=null) {
    return {
        type: SET_LAST_UPDATE,
        callback: callback,
        data: ts
    }
}

export function setCurrent(uuid, callback=null) {
    return {
        type: SET_CURRENT_HOTEL,
        callback: callback,
        data: uuid
    }
}

export function load(onSuccess, onError) {
    return function(dispatch, getState) {
        return HotelsApi.load('/0/100')
            .then(result => { dispatch(populate(result.data, {success: onSuccess, error: onError})) })
            .catch(error => { throw(error) })
    }
}