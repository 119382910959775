import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../../reducers'
// import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

// const logger = createLogger();

const finalCreateStore = compose(
    applyMiddleware(thunk)
)(createStore);

export default function Store(initialState) {
    return finalCreateStore(rootReducer, initialState)
}