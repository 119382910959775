export const ORIENTATION_PORTRAIT = 'P'
export const ORIENTATION_LANDSCAPE = 'L'

export function ucfirst (str) {
    str += ''
    let f = str.charAt(0).toUpperCase()
    return `${f}${str.substr(1)}`
}

export function roundD (nbr, decimal) {
    const precision = decimal || 2
    const tmp = Math.pow(10, precision)
    return Math.round( parseFloat(nbr) * tmp ) / tmp
}

export function srand (seed) {
    if (typeof seed === 'string') {
        str = seed
        seed = 0xFF
        for (let i = 0; i < str.length; i++) {
            seed ^= str.charCodeAt(i)
        }
    }

    return function (max, min) {
        max = max || 1
        min = min || 0
        seed = (seed * 9301 + 49297) % 233280

        return min + (seed / 233280) * (max - min)
    }
}

export function createId(size = 5) {
    const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

    let id = ''
    let now = Date.now()
    srand(now * 1000000)
    for(let i=0; i<size; i++) id += str[Math.floor(Math.random() * str.length)]
    return id
}

export function getEnviron()
{
    let __environ__ = 'dev'
    const hosts = window.location.host.split('.')

    if (hosts.length === 3 && hosts[2] === 'dev') __environ__ = 'stg'
    else if (hosts.length === 3
        && hosts[0] === 'admin'
        && hosts[1] === 'tunisian-travel'
        && hosts[2] === 'com') __environ__ = 'prd'

    localStorage.setItem('__environ__', __environ__)

    return __environ__
}