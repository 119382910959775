import {COLOR} from '../../services/theme'

export default {
    root: {
        backgroundColor: COLOR.white,
        border: `2px solid ${COLOR.line}`,
        borderRadius: 5,
        padding: 5
    }
}
