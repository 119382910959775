import React, {Fragment} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import * as AppMapping from '../../../actions/app'
import * as CategoriesMapping from '../../../actions/categories'

import CategoriesApi from '../../../api/categories'

import ComponentAbstract from '../../../components/ComponentAbstract'
import SimpleDialog from '../../../components/SimpleDialog'
import Description from '../../../components/Description'

import Translation from './Translations'

class DeleteItem extends ComponentAbstract {

    componentWillMount() {
        this.Translation = Translation
    }

    handleDelete = result => {
        this.showLoader(this.label('requesting'))

        const uuid = this.props.states.categories.current

        CategoriesApi.remove(uuid).then(result => {
            if (result.except || result.error)
                return this.handleLoadError(result)
            this.hideLoader(this.handleDeleteSuccess, result)
        })
    }

    handleDeleteSuccess = () => {
        this.showSnack(this.label('success'), 'success')

        this.props.actions.categories.load()

        this.resetDialog()
    }

    resetDialog = () => {
        this.props.handleClose()
    }

    render() {
        const { config } = this.props

        return (
            <Fragment>
                <SimpleDialog
                    config={config}
                    handleClose={this.resetDialog}
                    handleSuccess={this.handleDelete}
                    labelValidation={this.label('validate')}
                    canApply
                >
                    <Description text={this.label('description')} />
                </SimpleDialog>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states : {
            app: state.app,
            categories: state.categories
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            categories: bindActionCreators(CategoriesMapping, dispatch),
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DeleteItem)
