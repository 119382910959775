import {COLOR} from '../../services/theme'

export default theme => ({
    root: {
        top: 150,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    avatar: {
        backgroundColor: 'transparent !important',
        width: '100px !important',
        height: '100px !important'
    },
    logo: {
        padding: 10,
        width: 96,
        height: 96
    },
    title: {
        color: COLOR.white,
        fontSize: 56,
        fontWeight: 700
    }
})
