import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ActivitiesMapping from '../../../actions/activities'
import * as TmpMapping from '../../../actions/tmp'

import ActivitiesApi from '../../../api/activities'

import ComponentAbstract from '../../../components/ComponentAbstract'
import MenuItemIcon from '../../../components/MenuItemIcon'

import styles from './styles'
import Translation from './Translations'

class Settings extends ComponentAbstract {
    uuid = null

    state = {
        isLoaded: false,
        locale: 'FR',
        changes: {
            resume: false,
            informations: false
        },
        formValues: {
            resume: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            informations: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            }
        }
    }

    componentWillMount() {
        this.Translation = Translation
        this.setState({locale: this.getLocale(true)})
    }

    reload = () => {
        const item = this.getRecord('activities', this.uuid)
        if (!item) return

        this.props.actions.activities.setCurrent(
            item,
            {success: this.handleFillForm.bind(this)}
        )
    }

    handleSave = section => event => {
        this.showLoader(this.label(section, 'computing'))

        this.uuid = this.props.states.activities.current.uuid

        const item = {
            'uuid': this.uuid,
            [section]: this.state.formValues[section]
        }

        ActivitiesApi
            .save(section, item)
            .then(result => {
                if (result.except || result.error)
                    return this.handleLoadError(result)
                this.hideLoader(this.handleSuccess, section)
            })
    }

    handleSuccess = section => {
        this.hideLoader()
        this.showSnack(this.label(section, 'success'), 'success')

        this.setState({
            changes: {
                ...this.state.changes,
                [section]:false
            }
        }, this.handleCheckCommit.bind(this))
    }

    handleCheckCommit = () => {
        let hadCommit = false
        Object.entries(this.state.changes).map(([key, value]) => {
            if (value === true) hadCommit = true
        })

        if (!hadCommit) {
            this.props.actions.tmp.unload()
            this.props.actions.activities.load(true, this.reload.bind(this))
        }
    }

    handleChangeLocaleValue = (section, locale) => event => {
        this.setState({
            changes: {
                ...this.state.changes,
                [section]:true
            },
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [locale]: event.target.value
                }
            }
        })

        this.props.actions.tmp.populate(true)
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isLoaded: true,
            formValues: this.props.states.activities.current
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                resume: JSON.parse(this.props.states.activities.current.resume),
                informations: JSON.parse(this.props.states.activities.current.informations)
            }
        })
    }

    render() {
        const {classes, states, mode} = this.props
        const locales = this.label('locales', 'locales')

        if (mode === 'wizard' && !this.state.isLoaded) this.handleFillForm()

        return (
            <div className={classes.root}>

                <Grid container spacing={2} textAlign={'right'}>
                    <Grid item xs={12}>
                        <FormControl className={classes.select}>
                            <Select
                                value={this.state.locale}
                                onChange={this.handleChangeLocale}
                                variant={'outlined'}
                            >
                                {locales && Object.entries(locales).map(([key, value]) => {
                                    return (
                                        <MenuItem value={key}>
                                            <MenuItemIcon
                                                icon={'flag'}
                                                label={value}
                                            />
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} spacing={2} className={classes.mt10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('resume', 'settings')}</Typography>
                    </Grid>
                    <Grid xs={12} className={classes.mt10}>
                        <TextField
                            className={classes.field}
                            value={this.state.formValues.resume[this.state.locale]}
                            onChange={this.handleChangeLocaleValue('resume', this.state.locale)}
                            onKeyUp={this.handleChangeLocaleValue('resume', this.state.locale)}
                            type={'text'}
                            variant={'outlined'}
                            rows={10}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={'right'}>
                        <Button variant="outlined" onClick={this.handleSave('resume')}>{this.label('save')}</Button>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} spacing={2} className={classes.mt10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('informations', 'settings')}</Typography>
                    </Grid>
                    <Grid xs={12} className={classes.mt10}>
                        <TextField
                            className={classes.field}
                            value={this.state.formValues.informations[this.state.locale]}
                            onChange={this.handleChangeLocaleValue('informations', this.state.locale)}
                            onKeyUp={this.handleChangeLocaleValue('informations', this.state.locale)}
                            type={'text'}
                            variant={'outlined'}
                            rows={10}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={'right'}>
                        <Button variant="outlined" onClick={this.handleSave('informations')}>{this.label('save')}</Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            activities: bindActionCreators(ActivitiesMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Settings)
