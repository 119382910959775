import {COLOR} from '../../services/theme'

export default theme => ({
    root: {
        textAlign: 'center'
    },
    tip: {
        fontWeight: 'bold',
        fontSize: 20,
        color: COLOR.textMuted
    },
    iconTip: {
        fontSize: 96,
        color: COLOR.textMuted
    },
    avatar: {
        padding: 10
    },
    tipLink: {
        fontWeight: 'normal',
        fontSize: 20,
        cursor: 'pointer',
        color: COLOR.main
    }
})
