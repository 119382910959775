import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Icon from '@mui/material/Icon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ActivitiesMapping from '../../../actions/activities'

import ActivitiesApi from '../../../api/activities'

import ComponentAbstract from '../../../components/ComponentAbstract'
import BoldSwitch from '../../../components/BoldSwitch'

import styles from './styles'
import Translation from './Translations'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MenuItemIcon from "../../../components/MenuItemIcon";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

class Editor extends ComponentAbstract {
    hadTimerServices = false
    hadTimerPlaces = false
    hadTimerVisits = false
    hadTimerVehicles = false
    hadTimerRestaurants = false
    hadTimerCategories = null
    locale = 'FR'

    state = {
        isUpdated: false,
        formValues: {
            duration: 'H@1',
            contact: '',
            price: 0.00,
            capacity: 10,
            categories: [],
            services: [],
            places: [],
            place: null,
            visits: [],
            vehicles: [],
            restaurants: [],
            label: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            url: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            route: {
                'FR': 'activite',
                'EN': 'activity',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            description: {
                'FR': '',
                'EN': '',
                'ES': '',
                'DE': '',
                'IT': '',
                'RU': '',
                'CN': '',
                'AR': '',
                'JP': ''
            },
            isAvailable: true
        },
        categories: [],
        services: [],
        places: [],
        visits: [],
        vehicles: [],
        restaurants: []
    }

    componentWillMount() {
        this.Translation = Translation
        this.locale = this.getLocale(true)
    }

    loadItems = itemType => {
        const items = []

        for (let i = 0; i < this.props.states[itemType].items.length; i++) {
            let labels = JSON.parse(this.props.states[itemType].items[i].label)

            let item = {
                'label': labels[this.locale],
                'value': this.props.states[itemType].items[i].uuid
            }

            if (itemType === 'services' || itemType === 'categories') {
                if (this.props.states[itemType].items[i].categories.includes('activities')
                    || this.props.states[itemType].items[i].categories.includes('all')) {
                    item = {...item, 'icon': this.props.states[itemType].items[i].icon}
                }
            }
            else if (itemType === 'restaurants' || itemType === 'visits')
                item = { ...item, 'icon': this.label(this.props.states[itemType].items[i].category, `${itemType}_icons`) }
            else if (itemType === 'vehicles')
                item = {...item, 'icon': this.props.states[itemType].items[i].icon }

            if (item.icon) items.push(item)
        }

        this.setState({[itemType]: items})
    }

    loadPlaces = () => {
        const places = []

        for (let i = 0; i < this.props.states.places.items.length; i++) {
            places.push({
                'label': this.props.states.places.items[i].label,
                'value': this.props.states.places.items[i].uuid,
                'icon': 'location_on'
            })
        }

        this.setState({places: places})
    }

    handleChangeTags = field => (event, values, mode) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: values
            }
        })
    }

    handleFormSwitch = field =>event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.checked
            }
        })
    }

    handleChangeLocale = (section, field) => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [section]: {
                    ...this.state.formValues[section],
                    [field]: event.target.value
                }
            }
        })
    }

    handleChangeValue = field => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.value
            }
        })
    }

    handleForm = () => {
        this.showLoader(this.label('computing', this.props.mode))

        const arr = ['services', 'places', 'visits', 'vehicles', 'restaurants', 'categories']
        let item = this.state.formValues
        item = {
            ...item,
            selectedFile: null,
            place: this.state.formValues.place.value,
            services: [], places: [], visits: [], vehicles: [], restaurants: [], categories: []
        }

        if (!item.place || item.place.length < 36)
            return this.showSnack(this.label('place', 'warning'), 'warning')

        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < this.state.formValues[arr[i]].length; j++)
                item[arr[i]].push(this.state.formValues[arr[i]][j].value)

            item = {
                ...item,
                [arr[i]]: [...new Set(item[arr[i]])]
            }
        }

        if (this.props.mode === 'add') {
            ActivitiesApi
                .add(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
        else if (this.props.mode === 'edit') {
            ActivitiesApi
                .update(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
    }

    handleSuccess = (mode) => {
        this.showSnack(this.label(mode, 'success'), 'success')
        this.props.actions.activities.load()
        this.back()
    }

    categoriesIsLoaded = () => {
        if (!this.hadTimerCategories) {
            this.hadTimerCategories = true
            if (this.props.states.app.loading.categories.items !== 1)
                setTimeout(this.waitForCategories.bind(this), 1000)
            else this.loadItems('categories')
        }

        return this
    }

    waitForCategories = () => {
        if (this.props.states.app.loading.categories.items !== 1)
            return setTimeout(this.waitForCategories.bind(this), 1000)

        this.loadItems('categories')
    }

    servicesIsLoaded = () => {
        if (!this.hadTimerServices) {
            this.hadTimerServices = true
            if (this.props.states.app.loading.services.items !== 1)
                setTimeout(this.waitForServices.bind(this), 1000)
            else this.loadItems('services')
        }

        return this
    }

    waitForServices = () => {
        if (this.props.states.app.loading.services.items !== 1)
            return setTimeout(this.waitForServices.bind(this), 1000)

        this.loadItems('services')
    }

    placesIsLoaded = () => {
        if (!this.hadTimerPlaces) {
            this.hadTimerPlaces = true
            if (this.props.states.app.loading.places.items !== 1)
                setTimeout(this.waitForPlaces.bind(this), 1000)
            else this.loadPlaces()
        }

        return this
    }

    waitForPlaces = () => {
        if (this.props.states.app.loading.places.items !== 1)
            return setTimeout(this.waitForPlaces.bind(this), 1000)

        this.loadPlaces()
    }

    restaurantsIsLoaded = () => {
        if (!this.hadTimerRestaurants) {
            this.hadTimerRestaurants = true
            if (this.props.states.app.loading.restaurants.items !== 1)
                setTimeout(this.waitForRestaurants.bind(this), 1000)
            else this.loadItems('restaurants')
        }

        return this
    }

    waitForRestaurants = () => {
        if (this.props.states.app.loading.restaurants.items !== 1)
            return setTimeout(this.waitForRestaurants.bind(this), 1000)

        this.loadItems('restaurants')
    }

    visitsIsLoaded = () => {
        if (!this.hadTimerVisits) {
            this.hadTimerVisits = true
            if (this.props.states.app.loading.visits.items !== 1)
                setTimeout(this.waitForVisits.bind(this), 1000)
            else this.loadItems('visits')
        }

        return this
    }

    waitForVisits = () => {
        if (this.props.states.app.loading.visits.items !== 1)
            return setTimeout(this.waitForVisits.bind(this), 1000)

        this.loadItems('visits')
    }

    vehiclesIsLoaded = () => {
        if (!this.hadTimerVehicles) {
            this.hadTimerVehicles = true
            if (this.props.states.app.loading.vehicles.items !== 1)
                setTimeout(this.waitForVehicles.bind(this), 1000)
            else this.loadItems('vehicles')
        }

        return this
    }

    waitForVehicles = () => {
        if (this.props.states.app.loading.vehicles.items !== 1)
            return setTimeout(this.waitForVehicles.bind(this), 1000)

        this.loadItems('vehicles')
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.activities.current
        }, this.fillFormTransform.bind(this))
    }

    fillFormTransform = () => {
        let place = {}
        const selectedPlace = this.props.states.activities.current.place
        const allPlaces = this.props.states.places.items

        for (let i = 0; i < allPlaces.length; i++) {
            if (selectedPlace === allPlaces[i].uuid) {
                place = {
                    label: allPlaces[i].label,
                    value: allPlaces[i].uuid
                }
                break;
            }
        }

        this.setState({
            formValues: {
                ...this.state.formValues,
                label: JSON.parse(this.props.states.activities.current.label),
                description: JSON.parse(this.props.states.activities.current.description),
                url: JSON.parse(this.props.states.activities.current.url),
                route: JSON.parse(this.props.states.activities.current.route),
                categories: this.loadTransform('categories'),
                vehicles: this.loadTransform('vehicles'),
                services: this.loadTransform('services'),
                places: this.loadTransform('places'),
                visits: this.loadTransform('visits'),
                restaurants: this.loadTransform('restaurants'),
                place: place
            }
        })
    }

    loadTransform = (form) => {
        const result = []
        const selectedForm = this.props.states.activities.current[form] || []
        const all = this.props.states[form].items || []

        for (let i = 0; i < selectedForm.length; i++) {
            for (let j = 0; j < all.length; j++) {
                if (selectedForm[i] === all[j].uuid) {
                    if (form === 'places') {
                        result.push({
                            label: all[j].label,
                            value: all[j].uuid
                        })
                    } else {
                        let labels = JSON.parse(all[j].label)
                        result.push({
                            label: labels[this.locale],
                            value: all[j].uuid
                        })
                    }
                    break
                }
            }
        }

        return result
    }

    render() {
        const {classes, mode} = this.props

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        this
            .servicesIsLoaded()
            .categoriesIsLoaded()
            .placesIsLoaded()
            .visitsIsLoaded()
            .vehiclesIsLoaded()
            .restaurantsIsLoaded()

        const locales = this.label('locales', 'locales')
        const durations = this.label('durations', 'list')

        return (
            <div className={classes.formContainer}>
                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('name', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    className={classes.field}
                                    value={this.state.formValues.label[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                    onChange={this.handleChangeLocale('label', key)}
                                    onKeyUp={this.handleChangeLocale('label', key)}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('description', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('description', key)}
                                    onKeyUp={this.handleChangeLocale('description', key)}
                                    className={classes.field}
                                    value={this.state.formValues.description[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={classes.field}
                            label={this.label('capacity', 'form')}
                            margin={'normal'}
                            value={this.state.formValues.capacity}
                            type={'number'}
                            variant={'outlined'}
                            onChange={this.handleChangeValue('capacity')}
                            onKeyUp={this.handleChangeValue('capacity')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={classes.field}
                            label={this.label('price', 'form')}
                            margin={'normal'}
                            value={this.state.formValues.price}
                            type={'number'}
                            variant={'outlined'}
                            onChange={this.handleChangeValue('price')}
                            onKeyUp={this.handleChangeValue('price')}
                        />
                    </Grid>
                </Grid>

                <FormControl className={classes.select}>
                    <Select
                        value={this.state.formValues.duration}
                        onChange={this.handleChangeValue('duration')}
                        variant={'outlined'}
                    >
                        {durations && Object.entries(durations).map(([key, value]) => {
                            return (
                                <MenuItem value={key}>
                                    <MenuItemIcon
                                        icon={'schedule'}
                                        label={value}
                                    />
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.categories}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('categories')}
                    value={this.state.formValues.categories}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('categories', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.services}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('services')}
                    value={this.state.formValues.services}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('services', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.places}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('places')}
                    value={this.state.formValues.places}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('places', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    options={this.state.places}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('place')}
                    value={this.state.formValues.place}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>location_on</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('place', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.vehicles}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('vehicles')}
                    value={this.state.formValues.vehicles}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('vehicles', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.visits}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('visits')}
                    value={this.state.formValues.visits}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('visits', 'form')}  />
                    )}
                />

                <hr />

                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={this.state.restaurants}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={this.handleChangeTags('restaurants')}
                    value={this.state.formValues.restaurants}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={(<Icon>{option.icon}</Icon>)}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={this.label('restaurants', 'form')}  />
                    )}
                />

                <hr />

                <TextField
                    className={classes.field}
                    label={this.label('contact', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.contact}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('contact')}
                    onKeyUp={this.handleChangeValue('contact')}
                />

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('url', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('url', key)}
                                    onKeyUp={this.handleChangeLocale('url', key)}
                                    className={classes.field}
                                    value={this.state.formValues.url[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <hr />

                {locales && Object.entries(locales).map(([key, value]) => {
                    return (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography>{this.label('route', 'form')}: {`${value} (${key})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    onChange={this.handleChangeLocale('route', key)}
                                    onKeyUp={this.handleChangeLocale('route', key)}
                                    className={classes.field}
                                    value={this.state.formValues.route[key]}
                                    type={'text'}
                                    variant={'outlined'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Stack direction="column" spacing={1}>
                    <BoldSwitch
                        value={'isAvailable'}
                        label={this.label('is_available', 'form')}
                        onChange={this.handleFormSwitch('isAvailable')}
                        checked={this.state.formValues.isAvailable}
                    />
                </Stack>

                <Button variant={'contained'} onClick={this.handleForm} color={'primary'} className={classes.cta}>
                    {this.label(mode)}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            activities: bindActionCreators(ActivitiesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
