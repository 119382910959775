import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ActivitiesMapping from '../../../actions/activities'

import ActivitiesApi from '../../../api/activities'

import {getEnviron} from '../../../services/utils'

import ComponentAbstract from '../../../components/ComponentAbstract'

import styles from './styles'
import Translation from './Translations'
import Constants from '../../../data/Constants.json'

class Media extends ComponentAbstract {
    maxPhotoSize = 2

    state = {
        selectedFile: null
    }

    componentWillMount() {
        this.Translation = Translation
    }

    handleSelectFile = event => {
        this.setState({selectedFile: event.target.files[0] }, this.uploadHero.bind(this))
    }

    uploadHero = () => {
        if (this.state.selectedFile && this.state.selectedFile.size > (this.maxPhotoSize * 1024 * 1024))
            return this.showSnack(this.label('too_big', 'media'), 'warning')

        if (!this.state.selectedFile) return

        this.showLoader(this.label('media', 'computing'))

        ActivitiesApi
            .uploadHero(
                this.props.states.activities.current.uuid,
                this.state.selectedFile
            )
            .then(result => {
                if (result.except || result.error)
                    return this.handleLoadError(result)
                this.hideLoader(this.handleSuccess)
            })
    }

    handleSuccess = () => {
        this.showSnack(this.label('media', 'success'), 'success')
        this.setState({selectedFile: null})
    }

    render() {
        const {classes, states} = this.props
        const filename = this.state.selectedFile ?? null

        const env = getEnviron()
        const base = `${Constants[env].endpoint.protocol}://${Constants[env].endpoint.platform}/${Constants.picturesPath.activities}`
        const hero = `${base}/${states.activities.current.uuid}/hero.jpg` ?? ''

        return (
            <div className={classes.root}>
                <Grid container alignItems={'center'} spacing={2} className={classes.mt10}>
                    <Grid item xs={12}>
                        <Typography variant="h1">{this.label('hero', 'media')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={hero} alt={'hero'} className={classes.hero} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.zone}>
                            <input
                                type={"file"}
                                onChange={this.handleSelectFile}
                                name={"files[]"}
                                className={classes.inputField}
                                accept={"image/jpeg, image/png, image/jpg"}
                            />
                            <div className={classes.inputOverlay}>
                                <Icon className={classes.icon}>upload_file</Icon><br />
                                <Button
                                    variant={'contained'}
                                    size={'large'}
                                    color={'primary'}
                                >
                                    {this.label('select_media', 'media')}
                                </Button>
                                <p>{this.label('media_max_size', 'media')}</p>
                                {(!filename) ? '' : ( <p>{`${this.label('media_filename', 'media')}: `}<b>{`${filename.name}`}</b></p> )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            activities: bindActionCreators(ActivitiesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Media)
