import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const ArrayContainer = props => {
    const classes = useStyles();
    return (<Grid container spacing={props.spacing} className={classes.root} style={{marginTop: props.marginTop ?? 0}}>{props.children}</Grid>)
}

export default ArrayContainer
