import theme, {FOOTER_HEIGHT} from '../../services/theme'

export default {
    root: {
        flexGrow: 1,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: FOOTER_HEIGHT
        }
    }
}
