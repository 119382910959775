export const Cookie = {
    set: function(name, value, domain = 'localhost', days = 1) {
        const date = new Date()
        date.setTime(date.getTime()+(days*24*60*60*1000))

        const expires = (days) ? `; expires=${date.toGMTString()}` : ''

        document.cookie = (domain === 'localhost')
            ? `${name}=${value}${expires}; path=/`
            : `${name}=${value}${expires}; path=/; domain=${domain}`
    },

    get: function(name) {
        const nameEQ = `${name}=`
        const ca = document.cookie.split(';')

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') c = c.substring(1,c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
        }
        return null
    },

    erase: function(name, domain = 'localhost', value = '') {
        Cookie.set(name, value, domain, -1)
    }
}