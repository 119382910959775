import {COLOR} from '../../services/theme'

export default {
    label: {
        fontWeight: 500,
        color: COLOR.text,
        "& span": {
            fontSize: 12,
            color: COLOR.textMuted
        }
    },
    value: {
        textAlign: 'right',
        fontWeight: 700,
        color: COLOR.textLight
    },
    border: {
        borderBottom: `1px solid ${COLOR.line}`
    }
}