import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Icon from '@mui/material/Icon'

import React, {Fragment} from 'react'

import styles from './styles'
import {COLOR} from '../../services/theme'
const useStyles = makeStyles(styles)

export const MenuItemIcon = props => {
    const classes = useStyles();
    const iconColor = props.color || COLOR.textLight
    const iconColWidth = props.iconColWidth || 2
    const textColWidth = props.textColWidth || 10
    const iconBackgroundColor = props.iconBackgroundColor || 'bgWhite'

    return (
        <Grid container spacing={0}>
            <Grid item xs={iconColWidth} className={classes.iconAlign}>
                {(props.src || props.icon || props.coreIcon) ? (
                    <Avatar className={classes[iconBackgroundColor]}>
                        {(props.src) ? (
                            <img src={props.src} className={classes.logo} alt={props.alt} />
                        ) : ((props.coreIcon)
                                ? (props.coreIcon)
                                : (<Icon style={{color: iconColor}}>{props.icon}</Icon>)
                        )}
                    </Avatar>
                ) : ''}
            </Grid>
            <Grid item xs={textColWidth} className={(props.sublabel) ? classes.nameSubAlign : classes.nameAlign}>
                {props.label}
                {(props.sublabel) ? (
                    <Fragment>
                        <br />
                        <small>{props.sublabel}</small>
                    </Fragment>
                ) : ''}
            </Grid>
        </Grid>
    )
}

export default MenuItemIcon
