import {ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT, ucfirst} from '../../services/utils'
import {getCollection, setInMemory} from '../../services/store/utils'

const initialState = {
    section: {
        title: '',
        page: -1
    },
    display: {
        orientation: ((window.screen.availHeight > window.screen.availWidth) ? ORIENTATION_PORTRAIT : ORIENTATION_LANDSCAPE),
    },
    loader: {
        message: null,
        display: false
    },
    alert: {
        title: null,
        message: null,
        display: false
    },
    snack: {
        message: null,
        display: false,
        mode: 'info',
        duration: 10000
    },
    menu: {
        open: false
    },
    loading: {
        places: {
            items: 0
        },
        services: {
            items: 0
        },
        vehicles: {
            items: 0
        },
        visits: {
            items: 0
        },
        restaurants: {
            items: 0
        },
        hotels: {
            items: 0
        },
        tours: {
            items: 0
        },
        activities: {
            items: 0
        },
        categories: {
            items: 0
        },
        inns: {
            items: 0
        }
    }
}

export default function AppReducer(state = initialState, action) {

    // eslint-disable-next-line
    function appOrientation(state, action) {
        return getCollection('display', state, action)
    }

    // eslint-disable-next-line
    function appSetSection(state, action) {
        return setInMemory(state, action, 'section', 'title')
    }

    // eslint-disable-next-line
    function appSetSectionFooter(state, action) {
        return setInMemory(state, action, 'section', 'page')
    }

    // eslint-disable-next-line
    function appShowMenu(state, action) {
        return getCollection('menu', state, action)
    }

    // eslint-disable-next-line
    function appHideMenu(state, action) {
        return getCollection('menu', state, action)
    }

    // eslint-disable-next-line
    function appShowLoader(state, action) {
        return getCollection('loader', state, action)
    }

    // eslint-disable-next-line
    function appHideLoader(state, action) {
        return getCollection('loader', state, action)
    }

    // eslint-disable-next-line
    function appShowAlert(state, action) {
        return getCollection('alert', state, action)
    }

    // eslint-disable-next-line
    function appHideAlert(state, action) {
        return getCollection('alert', state, action)
    }

    // eslint-disable-next-line
    function appShowSnack(state, action) {
        return getCollection('snack', state, action)
    }

    // eslint-disable-next-line
    function appHideSnack(state, action) {
        return getCollection('snack', state, action)
    }

    // eslint-disable-next-line
    function appSetLoader(state, action) {
        return setInMemory(state, {data: action.data.value}, 'loading', action.data.section, action.data.loader)
    }

    // eslint-disable-next-line
    function appSetLoaders(state, action) {
        return setInMemory(state, {data: action.data.value}, 'loading', action.data.section)
    }

    try {
        // eslint-disable-next-line
        const exec = eval(`app${ucfirst(action.type)}`)
        const result = (exec) ? exec(state, action) : state

        if (action.callback && action.callback.success
            && (typeof(action.callback.success) ===  'function')) {
            setTimeout(() => {action.callback.success(result)}, 200)
        }

        return result
    }
    catch(e) { return state }
}
