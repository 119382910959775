import { withStyles } from '@mui/styles'

import Icon from '@mui/material/Icon'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'

import React, {Fragment} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import * as AppMapping from '../../actions/app'

import {getEnviron} from '../../services/utils'
import width from '../../services/theme/width'

import ComponentAbstract from '../../components/ComponentAbstract'

import Translation from './Translations'
import styles from './styles'

class Footer extends ComponentAbstract {

    state = {
        page: 0,
        anchorCardMenu: null,
        displayCardMenu: false
    }

    componentWillMount() {
        this.Translation = Translation
    }

    handleOpenMenu = event => {
        this.setState({
            anchorCardMenu: event.currentTarget,
            displayCardMenu: true
        })
    }

    handleCloseMenu = () => {
        this.setState({displayCardMenu: false})
    }

    onPageChange = (event, page) => {}

    render() {
        const {classes, states} = this.props

        const environ = getEnviron();

        return (
            <Fragment>

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: {
            app: state.app
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Footer)
