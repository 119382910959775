function getPath(position = -1, segType = 'hash', pathType = 'path') {
    let path

    if (segType === 'hash') {
        path = window.location.hash
        let segs = path.split('?')

        if (pathType === 'path') {
            path = segs[0].split('/')
            path.shift()
            return (position > -1) ? path[position] : path;
        } else if (pathType === 'search' && segs[1]) {
            path = segs[1].split('&')

            let res = {}
            for (let i = 0;  i < path.length; i++) {
                let search = path[i].split('=')
                res = {...res, [search[0]]: search[1]}
            }

            if (position !== 0 && res[position]) return res[position]
            else if (position !== 0 && !res[position]) return null
            else return res
        }
    } else if (segType === 'path') {
        path = window.location.pathname.split('/')
        path.shift()
        return (position > -1) ? path[position] : path;
    } else if (segType === 'search') {
        path = window.location.search
        let segs = path.split('?')

        if (!segs[1]) return null
        path = segs[1].split('&')

        let res = {}
        for (let i = 0;  i < path.length; i++) {
            let search = path[i].split('=')
            res = {...res, [search[0]]: search[1]}
        }

        if (position !== 0 && res[position]) return res[position]
        else if (position !== 0 && !res[position]) return null
        else return res
    }

    return null
}

export default getPath;