import { makeStyles } from '@mui/styles'
import Skeleton from '@mui/material/Skeleton'

import React, {Fragment} from 'react'

import Tip from '../Tip'

import styles from './styles'
const useStyles = makeStyles(styles)

export const DataLoader = props => {
    const classes = useStyles();

    const width = props.width || '50'
    const animation = props.animation || 'pulse'
    const label = props.label || null
    const classname = props.class || null

    let icon = props.icon || 'graphic_eq'
    icon = (props.noIcon) ? null : icon

    return (
        <div className={classname}>
            <Tip
                icon={icon}
                label={label}
            />
            <center>
                <Skeleton animation={animation} height={5} width={`${width}%`} className={classes.loader} />
            </center>
        </div>
    )
}

export default DataLoader
