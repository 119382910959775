import theme, {COLOR, HEADER_HEIGHT, SECTION_TITLE_PADDING} from '../../services/theme'

export default {
    root: {
        height: HEADER_HEIGHT,
        width: '100vw',
        backgroundColor: COLOR.main
    },
    toolbar: {
        marginRight: 10
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: '1 !important'
        },
        '& span': {
            fontSize: 20,
            top: -6,
            position: 'relative',
            fontWeight: 'bold',
            color: COLOR.white
        }
    },
    sectionTitle: {
        flexGrow: 1,
        paddingLeft: SECTION_TITLE_PADDING,
        fontSize: 20,
        position: 'relative',
        fontWeight: 'bold',
        color: COLOR.white,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            textAlign: 'right',
            paddingRight: 30
        }
    },
    logo: {
        height: '2em',
        display: 'inline-block',
        margin: '5px 5px 0px 0px'
    },
    menuButton: {
        color: COLOR.white
    },
    accountButton: {
        color: COLOR.white
    }
}