import ApiAbstract from '../ApiAbstract'

class JourneyApi extends ApiAbstract {
    /**
     * @type {string}
     */
    static baseUri = `/journey`

    /**
     * @param api
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static load(api='', callback=null, execCallback=true) {
        return this.getProcess(api, callback, execCallback)
    }

    /**
     * @param data
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static add(data, callback=null, execCallback=true) {
        return this.postProcess('', data,  callback, execCallback)
    }

    /**
     * @param stepUuid
     * @param tourUuid
     * @param photo
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static uploadPhoto(stepUuid, tourUuid, photo, callback=null, execCallback=true) {
        const formData = new FormData();
        formData.append('img', photo, photo.name)

        return this.postProcess(`/${tourUuid}/${stepUuid}`, formData,  callback, execCallback)
    }

    /**
     * @param uuid
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static remove(uuid, callback=null, execCallback=true) {
        return this.deleteProcess(`/${uuid}`, callback, execCallback)
    }

    /**
     * @param data
     * @param callback
     * @param execCallback
     * @returns {Promise<T|{data: *, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static update(data, callback=null, execCallback=true) {
        return this.putProcess(``, data, callback, execCallback)
    }
}

export default JourneyApi;