import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'

import cls from 'classnames'

import React, {PureComponent} from 'react'
import {compose} from 'redux'

import {generateUrl} from '../../services/router'
import LinkTo from '../LinkTo'

import styles from './styles'

class Tip extends PureComponent {
    render() {
        const { classes, avatar, icon, label, sublabel, linkLabel, type, className, style } = this.props

        const classesArray = [classes.root, className]
        const styleRoot = style || {}


        return (
            <Grid container alignItems={'center'} spacing={1} className={cls(classesArray)} style={styleRoot}>
                {(icon) ? (
                    <Grid item xs={12}>
                        <Icon className={classes.iconTip}>{icon}</Icon>
                    </Grid>
                ) : ''}
                {(avatar) ? (
                    <Grid item xs={12}>
                        <img src={avatar} className={classes.avatar} alt={label} />
                    </Grid>
                ) : ''}
                {(label) ? (
                    <Grid item xs={12}>
                        <span className={classes.tip}>
                            {label}
                        </span>
                    </Grid>
                ) : ''}
                {(linkLabel) ? (
                    <Grid item xs={12}>
                        <LinkTo className={classes.tipLink} to={generateUrl(this.props.link.path, this.props.link.params)}>
                            {(type === 'button') ? (
                                <Button variant={'contained'} color={'primary'} size={'small'}>
                                    {linkLabel}
                                </Button>
                            ) : linkLabel}
                        </LinkTo>
                    </Grid>
                ) : ''}
                {(sublabel) ? (
                    <Grid item xs={12}>
                        {sublabel}
                    </Grid>
                ) : ''}
            </Grid>
        )
    }
}

export default compose(
    withStyles(styles),
)(Tip)