import theme, {COLOR, MAX_HEIGHT, MAX_HEIGHT_XS_MD} from '../../../services/theme'

export default {
    debug: {
        border: '1px solid red !important'
    },
    mt10: {
        marginTop: '10px !important'
    },
    mtb10: {
        marginTop: '10px !important',
        marginBottom: '10px !important'
    },
    p5: {
        padding: 5
    },
    root: {
        width: '100%'
    },
    tabHeader: {
        width: '100%',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);'
    },
    tabContent: {
        padding: 20,
        paddingLeft: 90,
        paddingRight: 90,
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: MAX_HEIGHT,
        [theme.breakpoints.only("md")]: {
            maxHeight: MAX_HEIGHT_XS_MD,
            overflowY: 'unset'
        },
        [theme.breakpoints.only("sm")]: {
            maxHeight: MAX_HEIGHT_XS_MD,
            overflowY: 'unset'
        },
        [theme.breakpoints.only("xs")]: {
            maxHeight: MAX_HEIGHT_XS_MD,
            paddingLeft: 20,
            paddingRight: 20,
            overflowY: 'unset'
        }
    },
    select: {
        width: 250,
        [theme.breakpoints.only("xs")]: {
            width: '100%'
        }
    },
    field: {
        width: '100%'
    },
    zone: {
        marginTop: 10,
        border: `3px dashed ${COLOR.textMuted}`,
        padding: 10,
        textAlign: 'center',
        color: COLOR.text,
        height: 175
    },
    icon: {
        fontSize: 48
    },
    inputField: {
        position: 'relative',
        zIndex: 20,
        opacity: 0,
        height: '130px',
        width:'100%',
        border: "1px solid red"
    },
    inputOverlay: {
        position: 'relative',
        top: -100
    },
    autocomplete: {
        marginTop: '15px !important',
        width: '100%'
    },
    emptyContainer: {
        marginTop: '10%'
    },
    picture: {
        width: '100%',
        height: 200,
        objectFit: 'cover',
        borderRadius: 5
    },
    hero: {
        width: '100%',
        height: 450,
        objectFit: 'cover',
        borderRadius: 5
    },
    gridContainer: {
        height: `calc(${MAX_HEIGHT} - 120px)`,
        [theme.breakpoints.down('sm')]: {
            height: `calc(${MAX_HEIGHT_XS_MD} - 80px)`,
        }
    },
}
