import { makeStyles } from '@mui/styles'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const FlexRoot = props => {
    const classes = useStyles();
    return (<div className={classes.root}>{props.children}</div>)
}

export default FlexRoot
