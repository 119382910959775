import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import * as AppMapping from '../../actions/app'
import * as ProfileMapping from '../../actions/profile'

import {ROUTE_AUTH, ROUTE_TOURS} from '../../services/router'
import {getEnviron} from '../../services/utils'

import Layout from '../../components/Layout/Public'
import ComponentAbstract from '../../components/ComponentAbstract'
import Constants from '../../data/Constants.json'

class Loader extends ComponentAbstract {
    timerLogin = null

    componentWillMount() {
        const environ = getEnviron()
        const self = this

        this.showLoader(this.label('loading', 'actions'))

        this.timerLogin = setInterval(function () {
            if (!(
                self.props.states.profile
                && self.props.states.profile.isAuth
                && self.props.states.profile.uuid)
            ) self.redirectTo(ROUTE_AUTH, {page: 'login'})
        }, Constants[environ].timer.loginCheck)

        this.props.actions.app.setSectionFooter(1)
    }

    componentWillUnmount() {
        this.hideLoader()
        clearInterval(this.timerLogin)
    }

    render() {
        const { states }  = this.props

        if (states.profile && states.profile.isAuth && states.profile.uuid)
            return this.redirectTo(ROUTE_TOURS)

        return (<Layout pageId={"loader"}></Layout>)
    }
}

function mapStateToProps(state) {
    return {
        states : {
            app: state.app,
            profile: state.profile
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            profile: bindActionCreators(ProfileMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Loader)