import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

import React, {Fragment} from 'react'

import {generateUrl, ROUTE_AUTH, ROUTE_AUTH_MODE} from '../../../services/router'
import LinkTo from '../../../components/LinkTo'

import styles from './styles'
const useStyles = makeStyles(styles)

import Translation from './Translations.json'

export const ForgotPassword = props => {
    const classes = useStyles();

    const locale = (props.locale)
        ? props.locale
        : localStorage.getItem('__locale__')

    return (
       <Fragment>
           <div className={classes.containerInfo}>
               <span className={classes.subtitle}>TTT-ID</span>
               <p className={classes.text}>{Translation[locale].login.description}</p>
           </div>
           <div className={classes.ctaContainer}>
               <LinkTo to={generateUrl(ROUTE_AUTH, {page: 'login'})} style={{marginLeft: 10}}>
                   <Button variant={'outlined'} className={classes.cta}>
                       {Translation[locale].label.to_login}
                   </Button>
               </LinkTo>
           </div>
       </Fragment>
    )
}

export default ForgotPassword
