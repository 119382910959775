import axios from 'axios'
import {getEnviron} from '../services/utils'

import Constants from '../data/Constants'

/**
 * @todo Refacto promises
 */
class ApiAbstract {

    /**
     * @type {string}
     */
    static url = `${Constants[getEnviron()].endpoint.protocol}://${Constants[getEnviron()].endpoint.api}`

    /**
     * @param uri
     * @returns {string}
     */
    static getUrl(uri) { return `${this.url}${this.baseUri}${uri}` }

    /**
     * @returns {{headers: {token: (null|string)}}}
     */
    static getTokenHeader() { return ({headers: {"token" : ((localStorage.getItem('__token__') === 'public') ? null : localStorage.getItem('__token__'))}}) }

    /**
     * @param api
     * @param callback
     * @param execCallback
     * @param okStatus
     * @returns {Promise<T | {data: any, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static getProcess(api, callback={success:null, error:null, except:null}, execCallback=true, okStatus=200) {
        callback = callback || {success:null, error:null, except:null}
        return axios.get(
            this.getUrl(api), this.getTokenHeader())
            .then(result => {
                if (result.status === okStatus) {
                    const res = {except: false, error: false, data:result.data, callback:callback}

                    if (execCallback && callback.success && (typeof(callback.success) === 'function'))
                        callback.success(res)

                    return res
                }

                const res = {error: true, data:result, callback:callback}

                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)

                return res
            })
            .catch(e => {
                const res = {except: true, data:e, callback:callback}
                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)
                return res
            })
    }

    /**
     * @param api
     * @param data
     * @param callback
     * @param execCallback
     * @param okStatus
     * @returns {Promise<T | {data: any, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static postProcess(api, data={}, callback={success:null, error:null, except:null}, execCallback=true, okStatus=201) {
        callback = callback || {success:null, error:null, except:null}
        return axios.post(
            this.getUrl(api), data, this.getTokenHeader())
            .then(result => {
                if (result.status === okStatus) {
                    const res = {except: false, error: false, data:result.data, callback:callback}

                    if (execCallback && callback.success && (typeof(callback.success) === 'function'))
                        callback.success(res)

                    return res
                }

                const res = {error: true, data:result, callback:callback}

                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)

                return res
            })
            .catch(e => {
                const res = {except: true, data:e, callback:callback}
                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)
                return res
            })
    }

    /**
     * @param api
     * @param data
     * @param callback
     * @param execCallback
     * @param okStatus
     * @returns {Promise<T | {data: any, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static putProcess(api, data={}, callback={success:null, error:null, except:null}, execCallback=true, okStatus=200) {
        callback = callback || {success:null, error:null, except:null}
        return axios.put(
            this.getUrl(api), data, this.getTokenHeader())
            .then(result => {
                if (result.status === okStatus) {
                    const res = {except: false, error: false, data:result.data, callback:callback}

                    if (execCallback && callback.success && (typeof(callback.success) === 'function'))
                        callback.success(res)

                    return res
                }

                const res = {error: true, data:result, callback:callback}

                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)

                return res
            })
            .catch(e => {
                const res = {except: true, data:e, callback:callback}
                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)
                return res
            })
    }

    /**
     * @param api
     * @param callback
     * @param execCallback
     * @param okStatus
     * @returns {Promise<T | {data: any, callback: {success: null, except: null, error: null}, except: boolean}>}
     */
    static deleteProcess(api, callback={success:null, error:null, except:null}, execCallback=true, okStatus=200) {
        callback = callback || {success:null, error:null, except:null}
        return axios.delete(
            this.getUrl(api), this.getTokenHeader())
            .then(result => {
                if (result.status === okStatus) {
                    const res = {except: false, error: false, data:result.data, callback:callback}

                    if (execCallback && callback.success && (typeof(callback.success) === 'function'))
                        callback.success(res)

                    return res
                }

                const res = {error: true, data:result, callback:callback}

                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)

                return res
            })
            .catch(e => {
                const res = {except: true, data:e, callback:callback}
                if (execCallback && callback.error && (typeof(callback.error) === 'function'))
                    callback.error(res)
                return res
            })
    }
}

export default ApiAbstract;
