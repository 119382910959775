import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import cls from 'classnames'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

//import ProfileApi from '../../../../api/profile'

import * as AppMapping from '../../../../actions/app'

import {generateUrl, ROUTE_AUTH} from '../../../../services/router'
import {isEmail} from '../../../../services/utils/checker'
import width from '../../../../services/theme/width'

import ComponentAbstract from '../../../../components/ComponentAbstract'
import LinkTo from '../../../../components/LinkTo'
import SectionTitle from '../../../../components/SectionTitle'

import styles from '../../formStyles'
import Translation from '../Translations'

class PasswordForgot extends ComponentAbstract {

    state = {
        login: '',
        canConnect: false,
        results: null
    }

    componentWillMount() {
        this.Translation = Translation
        this.props.actions.app.setSection('')
    }

    handleChange = field => event => {
        if(event.keyCode == 13 && this.state.canConnect) return this.handleRequest()

        this.setState({
            [field]: event.target.value,
            canConnect: !!(isEmail(this.state.login))
        })
    }

    handleRequest = () => {
        this.showLoader(this.label('request_password', 'action'))

        /*ProfileApi.passwordRequest(
            {"email": this.state.login},
            this.handleRequestSuccess,
            this.handleLoadError
        )*/
    }

    handleRequestSuccess = (result) => {
        this.hideAlert()
            .hideLoader()
            .showSnack(this.label('request_password_success'), 'success', 10000);

        this.redirectTo(ROUTE_AUTH, {page: 'login'})
    }

    render() {
        const {classes, width} = this.props;

        return (
            <Grid container alignItems={'center'} justify={'center'} className={classes.container}>
                <Grid item>
                    <SectionTitle title={this.label('title')} size={'large'}/>
                    <TextField
                        className={classes.field}
                        label={this.label('email')}
                        margin={'normal'}
                        onChange={this.handleChange('login')}
                        onKeyUp={this.handleChange('login')}
                        value={this.state.login}
                        type={'email'}
                        variant={'outlined'}
                        autoFocus
                    />

                    <Grid container className={classes.ctas} spacing={2}>
                        {(width === 'xs' || width === 'sm') ? (
                            <Grid item className={classes.orderCtaForgot} xs={12}>
                                <LinkTo to={generateUrl(ROUTE_AUTH, {page: 'login'})}>
                                    <Button
                                        className={classes.cta}
                                        color={"primary"}
                                    >
                                        {this.label('login')}
                                    </Button>
                                </LinkTo>
                            </Grid>
                        ) : ''}
                        <Grid item  className={classes.orderCtaLogin} xs={12}>
                            <Button
                                className={cls(classes.cta, classes.ctaLogin)}
                                color={'primary'}
                                variant={'contained'}
                                disabled={!this.state.canConnect}
                                onClick={this.handleRequest}
                            >
                                {this.label('reinit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: {
            app: state.app
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(PasswordForgot)
