import {ucfirst} from '../../services/utils'
import {setAll, setInMemory} from '../../services/store/utils'

const initialState = {
    items: [],
    lastUpdate: null,
    current: null
}

export default function InnsReducer(state = initialState, action) {

    // eslint-disable-next-line
    function innsPopulateInns(state, action) {
        return setAll(state, action)
    }

    // eslint-disable-next-line
    function innsPopulateItemsInns(state, action) {
        return setInMemory(state, {data: action.data.items}, 'items')
    }

    // eslint-disable-next-line
    function innsUnloadInns(state, action) {
        return setAll(state, {data: initialState})
    }

    // eslint-disable-next-line
    function innsSetLastUpdateInns(state, action) {
        return setInMemory(state, action, 'lastUpdate')
    }

    // eslint-disable-next-line
    function innsSetCurrentInns(state, action) {
        return setInMemory(state, action, 'current')
    }

    try {
        // eslint-disable-next-line
        const exec = eval(`inns${ucfirst(action.type)}`)
        const result = (exec) ? exec(state, action) : state

        if (action.callback && action.callback.success
            && (typeof(action.callback.success) ===  'function')) {
            setTimeout(() => {action.callback.success(result)}, 200)
        }

        return result
    }
    catch(e) { return state }
}
