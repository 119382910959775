import theme,{ MAX_HEIGHT, MAX_HEIGHT_XS_MD } from "../../../services/theme";

export default {
    loaderContainer: {
        marginTop: '20%'
    },
    emptyContainer: {
        marginTop: '10%'
    },
    gridTopContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10
        }
    },
    gridAddContainer: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        }
    },
    gridContainer: {
        height: `calc(${MAX_HEIGHT} + 10px)`,
        [theme.breakpoints.down('sm')]: {
            height: `calc(${MAX_HEIGHT_XS_MD} + 20px)`,
        }
    },
    field: {
        width: '100%'
    }
}
