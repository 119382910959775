import {
    COLOR,
    HEADER_HEIGHT
} from '../../services/theme'

export default theme => ({
    drawer: {
        width: "100vw",
        maxWidth: "100vw",
        [theme.breakpoints.up("sm")]: {
            width: 350,
            maxWidth: 350,
        }
    },
    appBar: {
        height: HEADER_HEIGHT,
        backgroundColor: `${COLOR.main} !important`
    },
    appBarTitle: {
        flex: 1,
        textAlign: "left",
        color: COLOR.white
    },
    root: {
        overflowX: 'hidden',
        width: '100vw',
        display: 'flex',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        padding: 10,
        marginTop: HEADER_HEIGHT,
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
        [theme.breakpoints.up("sm")]: {
            alignItems: 'flex-start',
            flexDirection: 'row',
            marginTop: HEADER_HEIGHT,
            height: `calc(100vh - ${HEADER_HEIGHT}px)`
        },
        [theme.breakpoints.up("md")]: {
            width: 420
        }
    },
    fullview: {
        width: '100vw !important',
        padding: 10,
        marginTop: 'unset !important'
    }
})
