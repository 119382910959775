import theme, {
    COLOR,
    MENU_WIDTH,
    HEADER_HEIGHT,
    MENU_WIDTH_MOBILE
} from '../../services/theme'

export default {
    root: {
        overflow: 'hidden',
        backgroundColor: COLOR.body,
        borderRight: `2px solid ${COLOR.line}`,
        height: '100%',
        width: MENU_WIDTH,
        boxShadow: '30px 0px 40px -15px rgb(21 43 107 / 50%)',
        padding: 30,
        [theme.breakpoints.down("sm")]: {
            width: MENU_WIDTH_MOBILE,
            boxShadow: 'unset'
        }
    },
    containerTop: {
        height: HEADER_HEIGHT,
        flex: 1,
        display: 'flex',
        padding: 5,
        paddingTop: 0,
        marginBottom: 30
    },
    containerLogo: {
        width: 60
    },
    logo: {
        width: 64
    },
    containerTitle: {
        paddingTop: 2,
        paddingLeft: 10,
        flexBasis: '100%',
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        color: COLOR.text
    },
    subtitle: {
        color: COLOR.textLight
    },
    menuSection: {
        marginBottom: 15
    },
    menuHeader: {
        textTransform: 'uppercase',
        color: `${COLOR.textMuted} !important`,
        fontWeight: 'bolder !important',
        fontSize: 11,
        letterSpacing: '0.2em',
        height: 30,
        backgroundColor: 'transparent !important',
        borderBottom: `1px solid ${COLOR.line}`
    },
    menuLink: {
        marginTop: 5,
        height: 45,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: 700,
        letterSpacing: '0.001em'
    },
    menuIcon: {
        minWidth: 35
    },
    selectedButton: {
        backgroundColor: COLOR.white,
        '&:hover': {
            backgroundColor: COLOR.white
        },
        '& span': {
            color: COLOR.main
        }
    },
    unselectedButton: {
        color: COLOR.textMuted,
        '&:hover': {
            backgroundColor: COLOR.white,
            color: COLOR.main
        },
        '&:hover span': {
            backgroundColor: COLOR.transparent,
            color: COLOR.main
        }
    }
}