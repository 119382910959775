import { combineReducers } from 'redux'

import app from './app'
import tmp from './tmp'
import profile from './profile'
import places from './places'
import services from './services'
import categories from './categories'
import vehicles from './vehicles'
import visits from './visits'
import restaurants from './restaurants'
import hotels from './hotels'
import tours from './tours'
import inns from './inns'
import activities from './activities'
import journey from './journey'

const rootReducer = combineReducers({
    app,
    tmp,
    profile,
    places,
    services,
    categories,
    vehicles,
    visits,
    restaurants,
    hotels,
    tours,
    inns,
    activities,
    journey
})

export default rootReducer
