import React, {Fragment} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import * as AppMapping from '../../actions/app'

import ComponentAbstract from '../../components/ComponentAbstract'

class NotFound extends ComponentAbstract {
    render() {
        return (
            <Fragment>
                404 - Page not found
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states : {
            app: state.app
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(NotFound)
