export function isLogin(value) {
    const re = /[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1}/;
    return re.test(String(value).toLowerCase());
}

export function isEmail(value) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(value).toLowerCase());
}

export function isDigit(value) {
    const re = /[0-9]{1}/;
    return re.test(String(value).toLowerCase());
}
