import {POPULATE, POPULATE_ITEMS, UNLOAD, SET_LAST_UPDATE, SET_CURRENT_ACTIVITY} from './types'
import ActivitiesApi from '../../api/activities'

export function populate(data, callback=null, onlyItems = false) {
    return {
        type: (onlyItems) ? POPULATE_ITEMS : POPULATE,
        callback: callback,
        data: data
    }
}

export function unload(callback=null) {
    return {
        type: UNLOAD,
        callback: callback
    }
}

export function setLastUpdate(ts, callback=null) {
    return {
        type: SET_LAST_UPDATE,
        callback: callback,
        data: ts
    }
}

export function setCurrent(data, callback=null) {
    return {
        type: SET_CURRENT_ACTIVITY,
        callback: callback,
        data: data
    }
}

export function load(onlyItem = false, onSuccess = null, onError = null) {
    return function(dispatch, getState) {
        return ActivitiesApi.load('/0/100')
            .then(result => { dispatch(populate(result.data, {success: onSuccess, error: onError}, onlyItem)) })
            .catch(error => { throw(error) })
    }
}