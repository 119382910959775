import {COLOR} from '../../services/theme'

export default {
    iconAlign: {},
    nameAlign: {
        paddingTop: 12,
        paddingLeft: 10,
        fontSize: 14,
        fontWeight: 700,
        color: COLOR.textLight,
        textAlign: 'left'
    },
    nameSubAlign: {
        paddingTop: 8,
        paddingLeft: 10,
        fontSize: 14,
        fontWeight: 700,
        color: COLOR.textLight,
        textAlign: 'left'
    },
    bgWhite: {
        backgroundColor: `${COLOR.white} !important`
    },
    bgBody: {
        backgroundColor: `${COLOR.body} !important`
    },
    logo: {
        width: 32,
        height: 32
    }
}
