import axios from 'axios'
import {getEnviron} from '../../services/utils'

import Constants from '../../data/Constants'

class ProfileApi {

    static url = Constants[getEnviron()].endpoint.protocol  + '://' + Constants[getEnviron()].endpoint.api

    static getProfile() {
        const token = (localStorage.getItem('__token__') === 'public') ? null : localStorage.getItem('__token__')
        let api = this.url + '/profile'
        api += (localStorage.getItem('__token__') === 'public') ? '/public' : ''

        return axios.get(api, {headers: {"token" : token}})
            .then(response => { return response.data })
            .catch(error => { return error })
    }

    static isConnectedProfile() {
        const token = (localStorage.getItem('__token__') === 'public') ? null : localStorage.getItem('__token__')
        let api = this.url + '/profile'
        api += (localStorage.getItem('__token__') === 'public') ? '/public' : ''

        return axios.get(api, {headers: {"token" : token}})
    }

    static login(data, callbackSuccess, callbackError) {
        let api = this.url + '/profile/login'

        axios.post(api, data)
            .then(result => {
                if (result.status === 202) {
                    if (result.data && result.data.error) {
                        return callbackError(result.data)
                    } return callbackSuccess(result.data)
                } else return callbackError(result)
            })
            .catch(e => { callbackError(e) })
    }

    static loginWith2fa(data, callbackSuccess, callbackError) {
        let api = this.url + '/profile/loginWith2fa'

        axios.post(api, data)
            .then(result => {
                if (result.status === 202) {
                    if (result.data && result.data.error) {
                        return callbackError(result.data)
                    } return callbackSuccess(result.data)
                } else return callbackError(result)
            })
            .catch(e => { callbackError(e) })
    }

    static passwordRequest(data, callbackSuccess, callbackError) {
        let api = this.url + '/profile/password'

        axios.post(api, data)
            .then(result => {
                if (result.status === 201) {
                    if (result.data && result.data.error) {
                        return callbackError(result.data)
                    } return callbackSuccess(result.data)
                } else return callbackError(result)
            })
            .catch(e => { callbackError(e) })
    }

    static passwordUpdate(data, callbackSuccess, callbackError) {
        let api = this.url + '/profile/password'

        axios.put(api, data)
            .then(result => {
                if (result.status === 202) {
                    if (result.data && result.data.error) {
                        return callbackError(result.data)
                    } return callbackSuccess(result.data)
                } else return callbackError(result)
            })
            .catch(e => { callbackError(e) })
    }
}

export default ProfileApi;
