import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import React, {Fragment} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as AppMapping from '../../../actions/app'
//import * as ProfileMapping from '../../../actions/profile'

//import ProfileApi from '../../../api/profile'

import {ROUTE_LOADER} from '../../../services/router'
import width from '../../../services/theme/width'

import ComponentAbstract from '../../../components/ComponentAbstract'
import SectionTitle from '../../../components/SectionTitle'

import styles from '../formStyles'
import Translation from './Translations'

class G2fa extends ComponentAbstract {

    state = {
        code: '',
        canConnect: false,
        results: null
    }

    componentWillMount() {
        this.Translation = Translation
        this.props.actions.app.setSection('')
    }

    handleChange = field => event => {
        if(event.keyCode == 13 && this.state.canConnect) return this.handleCheck()

        this.setState({
            [field]: event.target.value,
            canConnect: (this.state.code.length === 6)
        })
    }

    handleCheck = () => {
        this.showLoader(this.label('check2fa', 'action'))

        /*ProfileApi.loginWith2fa({
                code: this.state.code,
                token: this.props.states.profile.token
            },
            this.handleCheckSuccess,
            this.handleLoadError
        )*/
    }

    handleCheckSuccess = (results) => {
        this.hideLoader().hideAlert()

        //this.props.actions.profile.autoLogin(results)

        this.redirectTo(ROUTE_LOADER)
    }

    render() {
        const {classes, width} = this.props

        return (
            <Fragment>
                {(width === 'xs') ? '' : (<SectionTitle title={this.label('title_2fa')} size={'large'} />)}
                <Grid container spacing={3} className={classes.gridFix}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.field}
                            label={this.label('g2fa')}
                            margin={'normal'}
                            onChange={this.handleChange('code')}
                            onKeyUp={this.handleChange('code')}
                            value={this.state.code}
                            type={'number'}
                            variant={'outlined'}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            className={classes.ctaLogin}
                            color={'primary'}
                            variant={'contained'}
                            disabled={!this.state.canConnect}
                            onClick={this.handleCheck}
                        >
                            {this.label('continue')}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: {
            //profile: state.profile
        },
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            //profile: bindActionCreators(ProfileMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(G2fa)
