import theme, {COLOR, HEADER_HEIGHT} from '../../../services/theme'

export default theme => ({
    containerInfo: {
        position: 'relative',
        top: HEADER_HEIGHT,
        left: 40,
        [theme.breakpoints.up('md')]: {
            top: 200
        }
    },
    subtitle: {
        color: COLOR.white,
        fontWeight: 700,
        fontSize: 24
    },
    text: {
        color: COLOR.white,
        fontWeight: 500,
        paddingRight: 50,
        [theme.breakpoints.up('md')]: {
            marginBottom: 20
        }
    },
    ctaContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        top: HEADER_HEIGHT,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            top: 220
        }
    },
    cta: {
        color: `${COLOR.white} !important` ,
        backgroundColor: 'transparent',
        border: `1px solid ${COLOR.white} !important`,
        "&:hover":{
            backgroundColor: `${COLOR.white} !important`,
            color: `${COLOR.textLight} !important`
        }
    }
})
