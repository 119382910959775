import { withStyles } from '@mui/styles'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'

import React from 'react'
import {compose} from 'redux'

import ComponentAbstract from '../ComponentAbstract'

import styles from './styles'

class SimpleDialog extends ComponentAbstract {

    transition = props => {
        return <Slide direction="up" {...props} />
    }

    render() {
        const { classes, children, config, handleClose, handleSuccess, labelCancelation, labelValidation, canApply, noCta, noCtaCancel, noCtaValid, noFullWidth } = this.props

        const fw = (noFullWidth) ? false : true

        const validationLabel = (labelValidation) ? labelValidation : this.label('ok')
        const cancelationLabel = (labelCancelation) ? labelCancelation : this.label('cancel')

        return (
            <Dialog
                open={config.display}
                onClose={handleClose}
                maxWidth={config.maxWidth}
                fullWidth={fw}
                TransitionComponent={this.transition}
            >
                {(!config.title) ? '' : (
                    <DialogTitle className={classes.dialogTitle}>{config.title}</DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText>
                        {children}
                    </DialogContentText>
                </DialogContent>
                {(!noCta) ? (
                    <DialogActions>
                        {(!noCtaCancel) ? (
                            <Button onClick={handleClose} color={'primary'}>
                                {cancelationLabel}
                            </Button>
                        ) : ''}
                        {(!noCtaValid) ? (
                            <Button onClick={handleSuccess} color={'primary'} disabled={!canApply}>
                                {validationLabel}
                            </Button>
                        ) : ''}
                    </DialogActions>
                ) : '' }
            </Dialog>
        )
    }
}

export default compose(
    withStyles(styles)
)(SimpleDialog)
