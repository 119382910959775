import { withStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Icon from '@mui/material/Icon'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as TmpMapping from '../../../actions/tmp'

import ComponentAbstract from '../../../components/ComponentAbstract'
import FlexRoot from '../../../components/FlexRoot'
import Description from '../../../components/Description'
import SimpleDialog from '../../../components/SimpleDialog'

import Settings from './settings'
import Journey from './Journey'
import Media from './media'

import styles from './styles'
import Translation from './Translations'

class Wizard extends ComponentAbstract {
    state = {
        selectedTab: 0,
        nextTab: 0,
        dialog: {
            commitItem: {display: false, title: ''}
        }
    }

    componentWillMount() {
        this.Translation = Translation
    }

    componentWillUnmount() {
        this.props.actions.tmp.unload()
    }

    handleTabChange = (event, newPage) => {
        if (this.props.states.tmp === true) {
            this.setState({nextTab: newPage})
            return this.handleOpenDialog('commitItem', this.label('title', 'commit'), 'md')
        }

        this.setState({selectedTab: newPage})
    }

    cancelCommit = () => {
        this.props.actions.tmp.unload()
        this.handleCloseDialogNoEvent('commitItem')
        this.setState({selectedTab: this.state.nextTab})
    }

    render() {
        const {classes, mode} = this.props

        let displayedTab = (<Fragment></Fragment>)
        if (this.state.selectedTab === 0) displayedTab = (<Settings mode={mode} />)
        else if (this.state.selectedTab === 1) displayedTab = (<Journey mode={mode} />)
        else if (this.state.selectedTab === 2) displayedTab = (<Media mode={mode} />)

        return (
            <Fragment>
                <SimpleDialog
                    config={this.state.dialog.commitItem}
                    handleClose={this.handleCloseDialog('commitItem')}
                    handleSuccess={this.cancelCommit}
                    labelCancelation={this.label('no')}
                    labelValidation={this.label('yes')}
                    canApply
                >
                    <Description text={this.label('warning', 'commit')} />
                </SimpleDialog>
                <div className={classes.root}>
                    <FlexRoot>
                        <div className={classes.tabHeader}>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.handleTabChange}
                                indicatorColor={'primary'}
                                textColor={'primary'}
                                variant={'fullWidth'}
                            >
                                <Tab icon={<Icon>assignment_turned_in</Icon>} label={this.label('general', 'tab')} />
                                <Tab icon={<Icon>ballot</Icon>} label={this.label('journey', 'tab')} />
                                <Tab icon={<Icon>perm_media</Icon>} label={this.label('media', 'tab')} />
                            </Tabs>
                        </div>
                        <div className={classes.tabContent}>
                            {displayedTab}
                        </div>
                    </FlexRoot>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Wizard)
