import { CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'

import React, {PureComponent} from 'react'
import classnames from 'classnames'

import styles from './styles'

class Loader extends PureComponent {
    render() {
        const { config, classes } = this.props

        return (
            <div className={classnames(classes.loader, classes[((config.display) ? 'enable' : 'disable')])}>
                <div className={classnames(classes.media)}>
                    {config.message}<br />
                    <CircularProgress className={classes.blue} />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Loader)
