import { withStyles } from '@mui/styles'
import { ThemeProvider } from '@mui/material/styles'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from "../../../actions/app"

import theme from '../../../services/theme'
import width from '../../../services/theme/width'
import {ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT} from '../../../services/utils'

import ComponentAbstract from '../../ComponentAbstract'

import Header from '../../Header'
import Loader from '../../Loader'
import SimpleSnack from '../../SimpleSnack'

import styles from './styles'

class Unauthorized extends ComponentAbstract {
    state = {
        width: null
    }

    componentWillMount() {
        window.addEventListener("orientationchange", this.onOrientationChange.bind(this))
    }

    onOrientationChange = (evt) => {
        const angle = (window.screen.availHeight > window.screen.availWidth)
            ? ORIENTATION_PORTRAIT : ORIENTATION_LANDSCAPE

        this.props.actions.app.orientation(angle)
    }

    hideSnack = () => { this.props.actions.app.hideSnack() }

    render = () => {
        const { appState, children, classes, width } = this.props

        return (
            <ThemeProvider theme={theme}>
                {(width !== 'xs' && width !== 'sm') ? '' : (
                    <Header
                        className={classes.header}
                        showMenuButton={false}
                    />
                )}
                <div className={classes.body}>
                    {children}
                </div>
                <Loader config={appState.loader} />
                <SimpleSnack config={appState.snack} handleClose={this.hideSnack} />
            </ThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    return {
        appState: state.app,
        width: width()
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Unauthorized)
