import { withStyles } from '@mui/styles'

import cls from 'classnames'

import React, {PureComponent} from 'react'
import {compose} from 'redux'

import {select} from '../../services/utils/selectors'

import styles from './styles'

class Map extends PureComponent {
    map = null
    marker = null
    mapRenderElement = null
    isInit = false

    componentDidMount() {
        this.mapRenderElement = select('#map')
    }

    initMap = () => {
        this.map = new google.maps.Map(this.mapRenderElement, {
            center: this.props.center.coordinate,
            zoom: this.props.zoom
        })

        this.marker = new google.maps.Marker({
            position: this.props.marker.coordinate,
            map: this.map
        })
    }



    render() {
        const { classes, className, style, center, marker, zoom } = this.props

        const classesArray = [classes.root, className]
        const styleRoot = style || {}

        if ((center.coordinate.lat !== 0 || center.coordinate.lng !== 0) && this.isInit === false) this.initMap();

        return (
            <div className={cls(classesArray)} style={styleRoot} id={'map'}></div>
        )
    }
}

export default compose(
    withStyles(styles)
)(Map)