import { makeStyles } from '@mui/styles'
import cls from 'classnames'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const Description = props => {
    const classes = useStyles();

    const classesArray = [classes.root, props.className]
    const style = props.style || {}

    return (<div className={cls(classesArray)} style={style}>{props.text}</div>)
}

export default Description
