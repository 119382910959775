import theme, {COLOR} from '../../services/theme'

export default theme => ({
    ctas: {
        marginTop: 20,
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    orderCtaLogin: {
        order: 2,
        [theme.breakpoints.down('sm')]: {
            order: 1
        }
    },
    orderCtaForgot: {
        order: 1,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            order: 2,
            width: '100%'
        }
    },
    orderCtaCreate: {
        order: 3,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            order: 3,
            width: '100%'
        }
    },
    cta: {
        height: 40,
        letterSpacing: 0.4,
        lineHeight: 'normal'
    },
    ctaLogin: {
        width: '100%',
        backgroundColor: COLOR.main
    },
    field: {
        width: '100%',
        "& div input": {
            fontWeight: 500,
            fontSize: 18,
            color: COLOR.text
        }
    },
    loginLogoContainer: {
        width: '100%',
        textAlign: 'center'
    },
    loginLogo: {
        height: 64,
        width: 'auto'
    },
    gridFix: {
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    containerFix: {
        [theme.breakpoints.only('xl')]: {
            paddingLeft: 400,
            paddingRight: 400,
            paddingTop: 10
        },
        [theme.breakpoints.only('lg')]: {
            paddingLeft: 300,
            paddingRight: 300,
            paddingTop: 10
        },
        [theme.breakpoints.only('sm')]: {
            padding: 10
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 30,
            paddingRight: 30
        }
    }
})
