import {
    LOGOUT_PROFILE_SUCCESS,
    LOAD_PROFILE_SUCCESS,
    AUTO_LOGIN,
    LOGIN_2FA
} from "../../actions/profile/types"

const initialState = {
    token: 'public',
    isAuth: false
}

export default function ProfileReducer(state = initialState, action) {
    if (action.type === LOAD_PROFILE_SUCCESS || action.type === AUTO_LOGIN || action.type === LOGIN_2FA)
        return  action.data
    else if (action.type === LOGOUT_PROFILE_SUCCESS || action.type === AUTO_LOGIN || action.type === LOGIN_2FA)
        return  action.data
    else return state
}
