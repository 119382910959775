import theme from "./index";

export default function width() {
    const width = window.innerWidth
    const bp = theme.breakpoints

    const sm = bp.values.sm //600
    const md = bp.values.md //900
    const lg = bp.values.lg //1200
    const xl = bp.values.xl //1536

    if (width < sm) return 'xs';
    if (width >= sm && width < md) return 'sm';
    if (width >= md && width < lg) return 'md';
    if (width >= lg && width < xl) return 'lg';
    if (width >= xl) return 'xl';
}