import {COLOR} from '../../services/theme'

export default {
    title: {
        color: COLOR.text,
        fontSize: 24
    },
    subtitle: {
        color: COLOR.textMuted,
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 20
    }
}