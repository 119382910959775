export const ORIENTATION = "orientation"

export const SET_SECTION = "setSection"
export const SET_SECTION_FOOTER = "setSectionFooter"

export const SHOW_MENU = "showMenu"
export const HIDE_MENU = "hideMenu"

export const SHOW_LOADER = "showLoader"
export const HIDE_LOADER = "hideLoader"

export const SHOW_ALERT = "showAlert"
export const HIDE_ALERT = "hideAlert"

export const SHOW_SNACK = "showSnack"
export const HIDE_SNACK = "hideSnack"

export const SET_LOADER = "setLoader"
export const SET_LOADERS = "setLoaders"

export const NONE_ = 'NONE_'