import { withStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt'
import AddLocationIcon from '@mui/icons-material/AddLocation'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as PlacesMapping from '../../../actions/places'
import width from '../../../services/theme/width'

import {generateUrl, ROUTE_PLACES_, ROUTE_PLACES__} from '../../../services/router'

import ComponentAbstract from '../../../components/ComponentAbstract'
import PageTitle from '../../../components/PageTitle'
import PageContainer from '../../../components/PageContainer'
import FlexRoot from '../../../components/FlexRoot'
import DrawerPanel from '../../../components/DrawerPanel'
import MenuItemIcon from '../../../components/MenuItemIcon'
import DataLoader from '../../../components/DataLoader'
import Tip from '../../../components/Tip'
import LinkTo from '../../../components/LinkTo'

import Editor from '../Editor'
import DeleteItem from '../DeleteItem'
import Viewer from '../Viewer'

import styles from './styles'
import Translation from './Translations'

class List extends ComponentAbstract {
    state = {
        panel: {
            editor: {display: false, title: ''},
            viewer: {display: false, title: ''}
        },
        dialog: {
            deleteItem: {display: false, title: ''}
        }
    }

    componentWillMount() {
        this.Translation = Translation
        this.props.actions.app.setSection(this.label('title'))
    }

    handleEditor = (mode, id = null) => {
        if (!this.state.panel.editor.display) {
            let title = this.label(`${mode}_title`)
            if (mode === 'editor' && id !== null) {
                const item = this.getRecord('places', id)
                if (!item) return
                this.props.actions.places.setCurrent(item)
                title = `${title}: ${item.label}`
            }
            this.handleOpenPanel('editor', title, 'right')
        }
    }

    handleViewer = (id) => {
        if (!this.state.panel.viewer.display) {
            if (id !== null) {
                const item = this.getRecord('places', id)
                if (!item) return
                this.props.actions.places.setCurrent(item)
            }
            this.handleOpenPanel('viewer', 'VIEWER', 'left')
        }
    }

    handleDelete = (uuid) => event => {
        this.props.actions.places.setCurrent(uuid)
        this.handleOpenDialog('deleteItem', this.label('delete_item'), 'md')
    }

    transformLabel = (param) => {
        return (
            <Fragment>
                <MenuItemIcon
                    iconColWidth={3}
                    textColWidth={9}
                    iconBackgroundColor={'bgBody'}
                    icon={'location_on'}
                    label={param.row.label}
                />
            </Fragment>
        )
    }

    transformPlace = (param) => {
        return (
            <Fragment>
                <MenuItemIcon
                    iconColWidth={3}
                    textColWidth={9}
                    iconBackgroundColor={'bgBody'}
                    icon={this.label(param.row.category, 'category_icon')}
                    label={this.label(param.row.category, 'category_text')}
                />
            </Fragment>
        )
    }

    transformCompass = (param) => {
        return (
            <Fragment>
                <MenuItemIcon
                    iconColWidth={3}
                    textColWidth={9}
                    iconBackgroundColor={'bgBody'}
                    icon={this.label(param.row.compass, 'localisation_icon')}
                    label={this.label(param.row.compass, 'localisation_text')}
                />
            </Fragment>
        )
    }

    bindActions = (param) => {
        return (
            <Stack direction="row" alignItems="center" justifyItems={"center"} spacing={0}>
                <LinkTo to={generateUrl(ROUTE_PLACES__, {mode: 'view', id: param.row.uuid})}>
                    <IconButton color="primary" component="edit">
                        <SatelliteAltIcon />
                    </IconButton>
                </LinkTo>
                <LinkTo to={generateUrl(ROUTE_PLACES__, {mode: 'edit', id: param.row.uuid})}>
                    <IconButton color="primary" component="edit">
                        <EditIcon />
                    </IconButton>
                </LinkTo>
                <IconButton color="danger" onClick={this.handleDelete(param.row.uuid)}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        )
    }

    handleSearch = (event, value, mode) => {
        this.redirectTo(ROUTE_PLACES__, {mode: 'edit', id: value.uuid})
    }

    render() {
        const {classes, width, states, action, id} = this.props

        if (action === 'add') this.handleEditor('creator')
        else if (action === 'edit') this.handleEditor('editor', id)
        else if (action === 'view') this.handleViewer(id)
        else this.checkPanelsCloseHandler(['editor', 'viewer'])

        let rowsPerPage = 10
        if (width === 'xs') rowsPerPage = 8
        if (width === 'sm') rowsPerPage = 14
        else if (width === 'md') rowsPerPage = 9
        else if (width === 'lg' || width === 'xl') rowsPerPage = 13

        const rows = states.places.items ?? []
        const columns = [
            { field: 'label', headerName: this.label('name', 'column'), width: 200, renderCell: this.transformLabel },
            { field: 'category', headerName: this.label('category', 'column'), width: 150, renderCell: this.transformPlace },
            { field: 'compass', headerName: this.label('compass', 'column'), width: 150, renderCell: this.transformCompass },
            { field: 'latitude', headerName: this.label('latitude', 'column'), width: 110 },
            { field: 'longitude', headerName: this.label('longitude', 'column'), flex: (width === 'xs') ? 0 : 1 },
            { field: 'actions', headerName: this.label('actions', 'column'), width: 150, renderCell: this.bindActions}
        ]

        const searchField = (
            <Autocomplete
                className={classes.field}
                options={rows}
                blurOnSelect
                onChange={this.handleSearch}
                getOptionLabel={(row) => {
                    return row.label
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={this.label('search_placeholder')}
                    />
                )}
            />
        )

        const addButton = (
            <LinkTo to={generateUrl(ROUTE_PLACES_, {mode: 'add'})}>
                <Button variant="contained" endIcon={<AddLocationIcon />}>
                    {this.label('add_cta')}
                </Button>
            </LinkTo>
        )

        const loader = states.app.loading.places.items ?? 2

        return (
            <Fragment>
                <DeleteItem
                    config={this.state.dialog.deleteItem}
                    handleClose={this.handleCloseDialog('deleteItem')}
                />
                <DrawerPanel
                    config={this.state.panel.viewer}
                    handleClose={this.handleClosePanels}
                    componentName={'viewer'}
                >
                    <Viewer mode={action} />
                </DrawerPanel>
                <DrawerPanel
                    config={this.state.panel.editor}
                    handleClose={this.handleClosePanels}
                    componentName={'editor'}
                >
                    <Editor mode={action} />
                </DrawerPanel>
                <PageContainer>
                    {(loader === 0) ? (
                        <DataLoader label={this.label('loading_data', 'actions')} class={classes.loaderContainer} />
                    ) : ''}

                    {(loader === 2) ? (
                        <div className={classes.loaderContainer}>
                            <Tip
                                icon={'wrong_location'}
                                label={this.label('loader_error')}
                            />
                        </div>
                    ) : ''}

                    {(loader === 1) ? (
                        <Fragment>
                            <Grid container alignItems={'center'} justifyContent={'space-around'} className={classes.gridTopContainer}>
                                <Grid item xs={6} sm={4}>
                                    <PageTitle
                                        title={this.label('page_title')}
                                        subtitle={this.label('page_subtitle')}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {(width !== 'xs') ? searchField : addButton}
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.gridAddContainer}>
                                    {(width === 'xs') ? searchField : addButton}
                                </Grid>
                            </Grid>
                            {(rows.length < 1) ? (
                                <div className={classes.emptyContainer}>
                                    <Tip
                                        icon={'add_location'}
                                        label={this.label('empty_list')}
                                        linkLabel={this.label('add_cta')}
                                        type={'button'}
                                        link={{
                                            path: ROUTE_PLACES_,
                                            params: {mode: 'add'}
                                        }}
                                    />
                                </div>
                            ) : (
                                <FlexRoot>
                                    <div className={classes.gridContainer}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            getRowId={(row) => row.uuid}
                                            pageSize={rowsPerPage}
                                            rowsPerPageOptions={[rowsPerPage]}
                                        />
                                    </div>
                                </FlexRoot>
                            )}
                        </Fragment>
                    ) : ''}
                </PageContainer>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state,
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            places: bindActionCreators(PlacesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(List)
