import CssBaseline from '@mui/material/CssBaseline'
import React, { Fragment } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { routes } from './services/router'
import { loadProfile, loadProfileSuccess } from './actions/profile'
import Store from './services/store'

import 'typeface-roboto'
import './index.css'

const store = Store()

let __token__ =  localStorage.getItem('__token__')
__token__ = (!__token__) ? 'public' : __token__

let __user__ =  localStorage.getItem('__user__')
__user__ = (!__user__) ? 'unknown_user' : __user__

const __isAuth__ =  localStorage.getItem('__isAuth__')
const __rememberMe__ =  localStorage.getItem('__rememberMe__')

let __locale__ =  localStorage.getItem('__locale__')
__locale__ = (!__locale__) ? 'fr' : __locale__

localStorage.setItem('__token__', __token__)
localStorage.setItem('__isAuth__', __isAuth__)
localStorage.setItem('__rememberMe__', __rememberMe__)
localStorage.setItem('__user__', __user__)
localStorage.setItem('__locale__', __locale__)

const savedProfile = {
    token: __token__,
    isAuth: __isAuth__,
    user: __user__,
    rememberMe: __rememberMe__
}

store.dispatch(loadProfileSuccess(savedProfile))
store.dispatch(loadProfile())

render(
    <Fragment>
        <CssBaseline />
        <BrowserRouter>
            <Provider store={store}>
                <Routes>
                    {routes.map((route, index) => (
                        <Route {...route} key={index} />
                    ))}
                </Routes>
            </Provider>
        </BrowserRouter>
    </Fragment>,
    document.getElementById("root")
)