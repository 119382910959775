import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import cls from 'classnames'

import React, {Fragment} from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const RowInfo = props => {
    const classes = useStyles();

    const labelClasses = [classes.label]
    const valueClasses = [classes.value]

    if (!props.isLast) {
        labelClasses.push(classes.border)
        valueClasses.push(classes.border)
    }

    const label_xs = props.xsLabel || 4
    const value_xs = props.xsValue || 8


    return (
        <Fragment>
            {(!props.label) ? '' : (
                <Grid item xs={label_xs} className={cls(labelClasses)}>
                    {props.label}
                </Grid>
            )}
            {(!props.value && !props.forceValueDisplay) ? '' : (
                <Grid item xs={value_xs} className={cls(valueClasses)}>
                    {props.value}
                </Grid>
            )}
        </Fragment>
    )
}

export default RowInfo
