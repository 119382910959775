import { createTheme } from '@mui/material/styles'

const COLOR_LIGHT = {
    main: 'rgba(60, 142, 254, 1)',
    mainLight: 'rgba(60, 142, 254, 0.6)',
    mainDark: 'rgba(14, 13, 55, 1)',
    secondary : 'rgba(16, 190, 167, 1)',
    body: 'rgba(248, 249, 253, 1)',
    dark: 'rgba(30, 41, 59, 1)',
    darkOpaq: 'rgba(30, 41, 59, 0.3)',
    text: 'rgba(74, 74, 74, 1)',
    textLight: 'rgba(82, 100, 132, 1)',
    textMuted: 'rgba(128, 148, 174, 1)',
    line: 'rgba(231, 235, 243, 1)',
    menuSelected: 'rgba(28, 80, 162, 1)',
    transparent: 'rgba(255, 255, 255, 0)',
    white: 'rgba(255, 255, 255, 1)',
    orange: 'rgba(247, 142, 54, 1)',
    green: 'rgb(16, 190, 167, 1)',
    red: '#f44336',
    synaps: {
        primary: '3c8efe',
        secondary: 'fafcfe'
    }
}

const COLOR_TTT = {
    main: 'rgba(7, 53, 102, 1)',
    mainLight: 'rgba(7, 53, 102, 0.6)',
    mainDark: 'rgba(14, 13, 55, 1)',
    secondary : 'rgba(16, 190, 167, 1)',
    body: 'rgba(248, 249, 253, 1)',
    dark: 'rgba(30, 41, 59, 1)',
    darkOpaq: 'rgba(30, 41, 59, 0.3)',
    text: 'rgba(74, 74, 74, 1)',
    textLight: 'rgba(82, 100, 132, 1)',
    textMuted: 'rgba(128, 148, 174, 1)',
    line: 'rgba(231, 235, 243, 1)',
    menuSelected: 'rgba(28, 80, 162, 1)',
    transparent: 'rgba(255, 255, 255, 0)',
    white: 'rgba(255, 255, 255, 1)',
    orange: 'rgba(247, 142, 54, 1)',
    green: 'rgb(16, 190, 167, 1)',
    red: '#f44336',
    synaps: {
        primary: '3c8efe',
        secondary: 'fafcfe'
    }
}

export let COLOR = COLOR_TTT

export const SUBMENU_HEIGHT = 115
export const SUBHEADER_HEIGHT = 100
export const SUBHEADER_HEIGHT_XS = 70
export const HEADER_HEIGHT = 60
export const FOOTER_HEIGHT = 60
export const SECTION_TITLE_PADDING = 250
export const MENU_WIDTH = 400
export const MENU_WIDTH_MOBILE = 290
export const WIDTH_13P = 1440

//export const MAX_HEIGHT_XS_MD = `calc(100vh - ${SUBMENU_HEIGHT}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`
export const MAX_HEIGHT_XS_MD = `calc(100vh - ${SUBMENU_HEIGHT}px - ${HEADER_HEIGHT}px)`
export const MAX_HEIGHT = `calc(100vh - ${SUBMENU_HEIGHT}px - ${HEADER_HEIGHT}px)`
export const MIN_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px + ${HEADER_HEIGHT}px)`

export default createTheme({
    palette: {
        primary: {
            contrastText: COLOR.white,
            main: COLOR.main
        },
        secondary: {
            contrastText: COLOR.white,
            main: COLOR.orange
        },
        danger: {
            contrastText: COLOR.red,
            main: COLOR.red
        }
    },
    typography: {
        color: COLOR.text,
        fontFamily: `Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif`,
        body1: {
            fontSize: 12,
            lineHeight: 1.25,
            letterSpacing: 0.5,
        },
        h1: {
            fontSize: 26,
            letterSpacing: 0.8,
        },
        useNextVariants: true
    }
})