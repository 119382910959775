import * as AppType from "./types"

export function orientation(angle, callback=null) {
    return {
        type: AppType.ORIENTATION,
        callback: callback,
        data: {
            orientation: angle
        }
    }
}

export function setSection(section, callback) {
    return {
        type: AppType.SET_SECTION,
        callback: callback,
        data: section
    }
}

export function setSectionFooter(page, callback) {
    return {
        type: AppType.SET_SECTION_FOOTER,
        callback: callback,
        data: page
    }
}

export function showMenu(callback=null) {
    return {
        type: AppType.SHOW_MENU,
        callback: callback,
        data: {open: true}
    }
}

export function hideMenu(callback=null) {
    return {
        type: AppType.HIDE_MENU,
        callback: callback,
        data: {open: false}
    }
}

export function showLoader(message, callback=null) {
    return {
        type: AppType.SHOW_LOADER,
        callback: callback,
        data: {
            message: message,
            display: true
        }
    }
}

export function hideLoader(callback=null) {
    return {
        type: AppType.HIDE_LOADER,
        callback: callback,
        data: {
            message: null,
            display: false
        }
    }
}

export function showAlert(message, title='', callback=null) {
    return {
        type: AppType.SHOW_ALERT,
        callback: callback,
        data: {
            message: message,
            title: title,
            display: true
        }
    }
}

export function hideAlert(callback=null) {
    return {
        type: AppType.HIDE_ALERT,
        callback: callback,
        data: {
            message: null,
            title: null,
            display: false
        }
    }
}

export function showSnack(message, mode='info', duration=5000, callback=null) {
    return {
        type: AppType.SHOW_SNACK,
        callback: callback,
        data: {
            message: message,
            mode: mode,
            duration: duration,
            display: true
        }
    }
}

export function hideSnack(callback=null) {
    return {
        type: AppType.HIDE_SNACK,
        callback: callback,
        data: {
            message: null,
            mode: 'info',
            duration: 5000,
            display: false
        }
    }
}

export function setLoader(section, loader, value = 0, callback=null) {
    return {
        type: AppType.SET_LOADER,
        callback: callback,
        data: {
            section: section,
            loader: loader,
            value: value
        }
    }
}

export function setLoaders(section, value, callback=null) {
    return {
        type: AppType.SET_LOADERS,
        callback: callback,
        data: {
            section: section,
            value: value
        }
    }
}
