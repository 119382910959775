import React from 'react'
import {useParams} from 'react-router-dom'

import Layout from '../../components/Layout/Authorized'

import List from './List'

export const Activities = props => {
    const match = useParams()

    return (
        <Layout pageId={'activities'}>
            <List
                action={match.mode ?? null}
                id={match.id ?? null}
            />
        </Layout>
    )
}

export default Activities