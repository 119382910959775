import { withStyles } from '@mui/styles'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import React, {Component} from 'react'

import styles from './styles'

class SimpleSnack extends Component {
    render() {
        const { className, classes, config, handleClose, ...other} = this.props

        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={config.display}
                autoHideDuration={config.duration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={config.mode} elevation={6} variant='filled' sx={{ width: '100%' }}>
                    {config.message}
                </Alert>
            </Snackbar>
        )
    }
}

export default withStyles(styles)(SimpleSnack)
