import { withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as PlacesMapping from '../../../actions/places'

import PlacesApi from '../../../api/places'

import ComponentAbstract from '../../../components/ComponentAbstract'
import MenuItemIcon from '../../../components/MenuItemIcon'
import BoldSwitch from '../../../components/BoldSwitch'

import styles from './styles'
import Translation from './Translations'

class Editor extends ComponentAbstract {
    state = {
        isUpdated: false,
        formValues: {
            label: '',
            latitude: '',
            longitude: '',
            category: 'city',
            compass: 'center',
            isAvailable: true
        }
    }

    componentWillMount() {
        this.Translation = Translation
    }

    handleChangeValue = field => event => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                [field]: event.target.value
            }
        })
    }

    handleAvailable = (event) => {
        this.setState({
            formValues: {
                ...this.state.formValues,
                ['isAvailable']: event.target.checked
            }
        })
    }

    handleForm = () => {
        const item = this.state.formValues;

        if (!item.label || item.label.length < 2)
            return this.showSnack(this.label('label', 'warning'), 'warning')

        if (!item.latitude || item.latitude.length < 1)
            return this.showSnack(this.label('latitude', 'warning'), 'warning')

        if (!item.longitude || item.longitude.length < 1)
            return this.showSnack(this.label('longitude', 'warning'), 'warning')

        this.showLoader(this.label('computing', this.props.mode))

        if (this.props.mode === 'add') {
            PlacesApi
                .add(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
        else if (this.props.mode === 'edit') {
            PlacesApi
                .update(item)
                .then(result => {
                    if (result.except || result.error)
                        return this.handleLoadError(result)
                    this.hideLoader(this.handleSuccess, this.props.mode)
                })
        }
    }

    handleSuccess = (mode) => {
        this.showSnack(this.label(mode, 'success'), 'success')
        this.props.actions.places.load();
        this.back()
    }

    handleFillForm = () => {
        setTimeout(this.fillForm.bind(this), 200)
    }

    fillForm = () => {
        this.setState({
            ...this.state,
            isUpdated: true,
            formValues: this.props.states.places.current
        })
    }

    render() {
        const {classes, mode} = this.props

        if (mode === 'edit' && !this.state.isUpdated) this.handleFillForm()

        return (
            <div className={classes.formContainer}>
                <TextField
                    className={classes.field}
                    label={this.label('name', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.label}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('label')}
                    onKeyUp={this.handleChangeValue('label')}
                />
                <TextField
                    className={classes.field}
                    label={this.label('latitude', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.latitude}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('latitude')}
                    onKeyUp={this.handleChangeValue('latitude')}
                />
                <TextField
                    className={classes.field}
                    label={this.label('longitude', 'form')}
                    margin={'normal'}
                    value={this.state.formValues.longitude}
                    type={'text'}
                    variant={'outlined'}
                    onChange={this.handleChangeValue('longitude')}
                    onKeyUp={this.handleChangeValue('longitude')}
                />
                <FormControl className={classes.select}>
                    <Select
                        value={this.state.formValues.compass}
                        onChange={this.handleChangeValue('compass')}
                        variant={'outlined'}
                    >
                        <MenuItem value={'center'}>
                            <MenuItemIcon
                                icon={this.label('center', 'localisation_icon')}
                                label={this.label('center', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'north'}>
                            <MenuItemIcon
                                icon={this.label('north', 'localisation_icon')}
                                label={this.label('north', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'northEast'}>
                            <MenuItemIcon
                                icon={this.label('northEast', 'localisation_icon')}
                                label={this.label('northEast', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'east'}>
                            <MenuItemIcon
                                icon={this.label('east', 'localisation_icon')}
                                label={this.label('east', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'southEast'}>
                            <MenuItemIcon
                                icon={this.label('southEast', 'localisation_icon')}
                                label={this.label('southEast', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'south'}>
                            <MenuItemIcon
                                icon={this.label('south', 'localisation_icon')}
                                label={this.label('south', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'southWest'}>
                            <MenuItemIcon
                                icon={this.label('southWest', 'localisation_icon')}
                                label={this.label('southWest', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'west'}>
                            <MenuItemIcon
                                icon={this.label('west', 'localisation_icon')}
                                label={this.label('west', 'localisation_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'northWest'}>
                            <MenuItemIcon
                                icon={this.label('northWest', 'localisation_icon')}
                                label={this.label('northWest', 'localisation_text')}
                            />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.select}>
                    <Select
                        value={this.state.formValues.category}
                        onChange={this.handleChangeValue('category')}
                        variant={'outlined'}
                    >
                        <MenuItem value={'city'}>
                            <MenuItemIcon
                                icon={this.label('city', 'category_icon')}
                                label={this.label('city', 'category_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'airport'}>
                            <MenuItemIcon
                                icon={this.label('airport', 'category_icon')}
                                label={this.label('airport', 'category_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'reserve'}>
                            <MenuItemIcon
                                icon={this.label('reserve', 'category_icon')}
                                label={this.label('reserve', 'category_text')}
                            />
                        </MenuItem>
                        <MenuItem value={'location'}>
                            <MenuItemIcon
                                icon={this.label('location', 'category_icon')}
                                label={this.label('location', 'category_text')}
                            />
                        </MenuItem>
                    </Select>
                </FormControl>
                <BoldSwitch
                    value={'isAvailable'}
                    label={this.label('is_available', 'form')}
                    onChange={this.handleAvailable}
                    checked={this.state.formValues.isAvailable}
                />
                <Button variant={'contained'} onClick={this.handleForm} color={'primary'} className={classes.cta}>
                    {this.label(mode)}
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            places: bindActionCreators(PlacesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Editor)
