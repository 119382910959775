import { makeStyles } from '@mui/styles'

import React, {Fragment} from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const PageTitle = props => {
    const classes = useStyles();
    return (
        <Fragment>
            <div className={classes.title}>
                {props.title}
            </div>
            <div className={classes.subtitle}>
                {props.subtitle}
            </div>
        </Fragment>
    )
}

export default PageTitle
