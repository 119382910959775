import { withStyles } from '@mui/styles'
import { ThemeProvider } from '@mui/material/styles'

import Drawer from '@mui/material/Drawer'
import PropTypes from 'prop-types'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import ProfileApi from '../../../api/profile'

import * as AppMapping from "../../../actions/app"
import * as ProfileMapping from '../../../actions/profile'
import * as TmpMapping from '../../../actions/tmp'
import * as PlacesMapping from '../../../actions/places'
import * as ServicesMapping from '../../../actions/services'
import * as VehiclesMapping from '../../../actions/vehicles'
import * as VisitsMapping from '../../../actions/visits'
import * as RestaurantsMapping from '../../../actions/restaurants'
import * as HotelsMapping from '../../../actions/hotels'
import * as ToursMapping from '../../../actions/tours'
import * as InnsMapping from '../../../actions/inns'
import * as ActivitiesMapping from '../../../actions/activities'
import * as CategoriesMapping from '../../../actions/categories'

import {ROUTE_LOADER} from '../../../services/router'
import theme from '../../../services/theme'
import width from '../../../services/theme/width'
import {getEnviron, ORIENTATION_LANDSCAPE, ORIENTATION_PORTRAIT} from '../../../services/utils'
import ComponentAbstract from '../../ComponentAbstract'

import Menu from '../../Menu'
import Header from '../../Header'
import Footer from '../../Footer'
import Loader from '../../Loader'
import SimpleSnack from '../../SimpleSnack'
import SimpleAlert from '../../SimpleAlert'

//import MockedData_places from '../../../data/mock/places.json'

import Constants from '../../../data/Constants.json'

import styles from './styles'

class Layout extends ComponentAbstract {
    now = null
    timerReload = null
    timerCheck = null
    env = 'dev'

    state = {
        width: null,

    }

    componentWillMount() {
        window.addEventListener("orientationchange", this.onOrientationChange.bind(this))
        this.now = new Date()
        this.env = getEnviron()

        this.loadData()
        this.timerReload = setInterval(this.onTimerReload.bind(this), Constants[this.env].timer.loginReload)
        this.timerCheck = setInterval(this.onTimerCheck.bind(this), Constants[this.env].timer.walletCheck)
    }

    componentWillUnmount() {
        clearInterval(this.timerReload)
        clearInterval(this.timerCheck)
    }

    onTimerCheck = () => {}

    onTimerReload = () => {
        this.isConnected()
    }

    isConnected = () => {
        ProfileApi
            .isConnectedProfile()
            .then(result => {
                if (result.except || result.error)
                    return this.logout()
                this.loadData()
            })
            .catch(error => { return this.logout() })
    }

    loadData = () => {
        this
            .loadServices()
            .loadCategories()
            .loadPlaces()
            .loadVehicles()
            .loadVisits()
            .loadRestaurants()
            .loadHotels()
            .loadActivities()
            .loadTours()
            .loadInns()
    }

    loadVisits = () => {
        const lastUpdate = (this.props.states.visits.lastUpdate !== null)
            ? new Date(this.props.states.visits.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.visits.load(
                this.loadVisitsSuccess.bind(this),
                this.loadVisitsError.bind(this)
            )

            this.props.actions.visits.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadVisitsSuccess = () => {
        this.props.actions.app.setLoaders('visits', {
            items: 1
        })
    }

    loadVisitsError = () => {
        this.props.actions.app.setLoaders('visits', {
            items: 2
        })
    }

    loadVehicles = () => {
        const lastUpdate = (this.props.states.vehicles.lastUpdate !== null)
            ? new Date(this.props.states.vehicles.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.vehicles.load(
                this.loadVehiclesSuccess.bind(this),
                this.loadVehiclesError.bind(this)
            )

            this.props.actions.vehicles.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadVehiclesSuccess = () => {
        this.props.actions.app.setLoaders('vehicles', {
            items: 1
        })
    }

    loadVehiclesError = () => {
        this.props.actions.app.setLoaders('vehicles', {
            items: 2
        })
    }

    loadHotels = () => {
        const lastUpdate = (this.props.states.hotels.lastUpdate !== null)
            ? new Date(this.props.states.hotels.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.hotels.load(
                this.loadHotelsSuccess.bind(this),
                this.loadHotelsError.bind(this)
            )

            this.props.actions.hotels.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadHotelsSuccess = () => {
        this.props.actions.app.setLoaders('hotels', {
            items: 1
        })
    }

    loadHotelsError = () => {
        this.props.actions.app.setLoaders('hotels', {
            items: 2
        })
    }

    loadRestaurants = () => {
        const lastUpdate = (this.props.states.restaurants.lastUpdate !== null)
            ? new Date(this.props.states.restaurants.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.restaurants.load(
                this.loadRestaurantsSuccess.bind(this),
                this.loadRestaurantsError.bind(this)
            )

            this.props.actions.restaurants.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadRestaurantsSuccess = () => {
        this.props.actions.app.setLoaders('restaurants', {
            items: 1
        })
    }

    loadRestaurantsError = () => {
        this.props.actions.app.setLoaders('restaurants', {
            items: 2
        })
    }

    loadServices = () => {
        const lastUpdate = (this.props.states.services.lastUpdate !== null)
            ? new Date(this.props.states.services.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.services.load(
                this.loadServicesSuccess.bind(this),
                this.loadServicesError.bind(this)
            )

            this.props.actions.services.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadServicesSuccess = () => {
        this.props.actions.app.setLoaders('services', {
            items: 1
        })
    }

    loadServicesError = () => {
        this.props.actions.app.setLoaders('services', {
            items: 2
        })
    }

    loadCategories = () => {
        const lastUpdate = (this.props.states.categories.lastUpdate !== null)
            ? new Date(this.props.states.categories.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.categories.load(
                this.loadCategoriesSuccess.bind(this),
                this.loadCategoriesError.bind(this)
            )

            this.props.actions.categories.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadCategoriesSuccess = () => {
        this.props.actions.app.setLoaders('categories', {
            items: 1
        })
    }

    loadCategoriesError = () => {
        this.props.actions.app.setLoaders('categories', {
            items: 2
        })
    }

    loadPlaces = () => {
        const lastUpdate = (this.props.states.places.lastUpdate !== null)
            ? new Date(this.props.states.places.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.places.load(
                this.loadPlacesSuccess.bind(this),
                this.loadPlacesError.bind(this)
            )

            this.props.actions.places.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadPlacesSuccess = () => {
        this.props.actions.app.setLoaders('places', {
            items: 1
        })
    }

    loadPlacesError = () => {
        this.props.actions.app.setLoaders('places', {
            items: 2
        })
    }

    loadTours = () => {
        const lastUpdate = (this.props.states.tours.lastUpdate !== null)
            ? new Date(this.props.states.tours.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.tours.load(
                false,
                this.loadToursSuccess.bind(this),
                this.loadToursError.bind(this)
            )

            this.props.actions.tours.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadToursSuccess = () => {
        this.props.actions.app.setLoaders('tours', {
            items: 1
        })
    }

    loadToursError = () => {
        this.props.actions.app.setLoaders('tours', {
            items: 2
        })
    }

    loadInns = () => {
        const lastUpdate = (this.props.states.inns.lastUpdate !== null)
            ? new Date(this.props.states.inns.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.inns.load(
                false,
                this.loadInnsSuccess.bind(this),
                this.loadInnsError.bind(this)
            )

            this.props.actions.inns.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadInnsSuccess = () => {
        this.props.actions.app.setLoaders('inns', {
            items: 1
        })
    }

    loadInnsError = () => {
        this.props.actions.app.setLoaders('inns', {
            items: 2
        })
    }

    loadActivities = () => {
        const lastUpdate = (this.props.states.activities.lastUpdate !== null)
            ? new Date(this.props.states.activities.lastUpdate)
            : null

        if (!lastUpdate || (this.now.getTime() - lastUpdate.getTime() > Constants[this.env].timer.reloadCheck)) {
            this.props.actions.activities.load(
                false,
                this.loadActivitiesSuccess.bind(this),
                this.loadActivitiesError.bind(this)
            )

            this.props.actions.activities.setLastUpdate(this.now.getTime())
        }

        return this
    }

    loadActivitiesSuccess = () => {
        this.props.actions.app.setLoaders('activities', {
            items: 1
        })
    }

    loadActivitiesError = () => {
        this.props.actions.app.setLoaders('activities', {
            items: 2
        })
    }

    onOrientationChange = (evt) => {
        const angle = (window.screen.availHeight > window.screen.availWidth)
            ? ORIENTATION_PORTRAIT : ORIENTATION_LANDSCAPE

        this.props.actions.app.orientation(angle)
    }

    render = () => {
        const { width, actions, states, children, classes, pageId } = this.props

        if (!states.profile.uuid && !states.profile.isAuth) {
            this.redirectTo(ROUTE_LOADER)
            return (<Fragment></Fragment>)
        } else {
            let menuConfig = { showMenuButton: false, menuOpen: false, variant: 'temporary' }
            if (pageId !== 'loader') {
                menuConfig = ((width === 'xs' || width === 'sm' || width === 'md' || width === 'lg'))
                    ? { showMenuButton: true, menuOpen: states.app.menu.open, variant: 'temporary' }
                    : { showMenuButton: false, menuOpen: true, variant: 'permanent' }
            }

            return (
                <ThemeProvider theme={theme}>
                    <Loader config={states.app.loader} />
                    <Header showMenuButton={menuConfig.showMenuButton} auth />
                    <Footer />
                    <Drawer
                        anchor={"left"}
                        open={menuConfig.menuOpen}
                        variant={menuConfig.variant}
                        onClick={actions.app.hideMenu}
                    >
                        <Menu pageId={pageId}/>
                    </Drawer>
                    <div className={classes.body}>
                        {children}
                    </div>
                    <SimpleAlert
                        config={states.app.alert}
                        handleClose={this.hideAlert}
                    />
                    <SimpleSnack
                        config={states.app.snack}
                        handleClose={this.hideSnack}
                    />
                </ThemeProvider>
            )
        }
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        width: PropTypes.string.isRequired
    }
}

function mapStateToProps(state) {
    return {
        states : state,
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            profile: bindActionCreators(ProfileMapping, dispatch),
            tmp: bindActionCreators(TmpMapping, dispatch),
            places: bindActionCreators(PlacesMapping, dispatch),
            services: bindActionCreators(ServicesMapping, dispatch),
            vehicles: bindActionCreators(VehiclesMapping, dispatch),
            visits: bindActionCreators(VisitsMapping, dispatch),
            restaurants: bindActionCreators(RestaurantsMapping, dispatch),
            hotels: bindActionCreators(HotelsMapping, dispatch),
            tours: bindActionCreators(ToursMapping, dispatch),
            inns: bindActionCreators(InnsMapping, dispatch),
            activities: bindActionCreators(ActivitiesMapping, dispatch),
            categories: bindActionCreators(CategoriesMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Layout)
