import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import * as AppMapping from '../../actions/app'

import Layout from '../../components/Layout/Authorized'

export const Booking = props => {
    return (
        <Layout pageId={'booking'}>
            !! RESERVATIONS !!
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        states : {
            app: state.app
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Booking)