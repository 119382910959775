import { withStyles } from '@mui/styles'
import cls from 'classnames'

import Drawer from '@mui/material/Drawer'
import Icon from "@mui/material/Icon"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import AppBar from "@mui/material/AppBar"

import React, {Component} from "react"
import {compose} from "redux"

import styles from "./styles"

class DrawerPanel extends Component {

    onCloseDrawer = () => {
        this.props.handleClose([this.props.componentName])
    }

    render() {
        const {
            classes,
            config,
            handleClose,
            componentName,
            children,
            fullview
        } = this.props

        const rootCls = [classes.root]

        if (fullview) rootCls.push(classes.fullview)

        return (
            <Drawer anchor={config.direction || 'right'} open={config.display} onClose={this.onCloseDrawer} className={classes.drawer}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography className={classes.appBarTitle}>
                            {config.title}
                        </Typography>
                        <IconButton color="inherit" onClick={this.onCloseDrawer}>
                            <Icon id={componentName}>close_circle</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={cls(rootCls)}>
                    {children}
                </div>
            </Drawer>
        )
    }
}

export default compose(
    withStyles(styles)
)(DrawerPanel)

