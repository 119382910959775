import { makeStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import cls from 'classnames'

import React from 'react'

import styles from './styles'
const useStyles = makeStyles(styles)

export const BoldSwitch = props => {
    const classes = useStyles();

    const value = props.value || 'enabled'
    const labelPlacement = props.labelPlacement || 'end'
    const classesArray = [classes.root, props.className]
    const style = props.style || {}

    return (
        <FormControlLabel
            labelPlacement={labelPlacement}
            control={ <Switch color={'primary'} value={value}/> }
            label={props.label}
            onChange={(props.onChange) ? props.onChange : () => {}}
            checked={props.checked}
            className={cls(classesArray)}
            style={style}
        />
    )
}

export default BoldSwitch
