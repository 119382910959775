import theme, {COLOR, FOOTER_HEIGHT} from '../../services/theme'

export default {
    root: {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        position: "fixed",
        bottom: 0,
        backgroundColor: COLOR.white,
        height: FOOTER_HEIGHT,
        width: '100%',
        zIndex: 2,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    }
}