import * as Pages from '../../pages'

export const ROUTE_LOADER = {
    element: <Pages.Loader />,
    path: '/'
}

export const ROUTE_AUTH = {
    element: <Pages.Auth />,
    path: '/auth/:page',
}

export const ROUTE_AUTH_MODE = {
    element: <Pages.Auth />,
    path: '/auth/:page/:mode',
}

export const ROUTE_AUTH_MODE_ID = {
    element: <Pages.Auth />,
    path: '/auth/:page/:mode/:id',
}

export const ROUTE_DASHBOARD = {
    element: <Pages.Dashboard />,
    path: '/dashboard',
}

export const ROUTE_VEHICLES = {
    element: <Pages.Vehicles />,
    path: '/elements/vehicles',
}

export const ROUTE_VEHICLES_ = {
    element: <Pages.Vehicles />,
    path: '/elements/vehicles/:mode',
}

export const ROUTE_VEHICLES__ = {
    element: <Pages.Vehicles />,
    path: '/elements/vehicles/:mode/:id',
}

export const ROUTE_PLACES = {
    element: <Pages.Places />,
    path: '/elements/places',
}

export const ROUTE_PLACES_ = {
    element: <Pages.Places />,
    path: '/elements/places/:mode',
}

export const ROUTE_PLACES__ = {
    element: <Pages.Places />,
    path: '/elements/places/:mode/:id',
}

export const ROUTE_HOTELS = {
    element: <Pages.Hotels />,
    path: '/elements/hotels',
}

export const ROUTE_HOTELS_ = {
    element: <Pages.Hotels />,
    path: '/elements/hotels/:mode',
}

export const ROUTE_HOTELS__ = {
    element: <Pages.Hotels />,
    path: '/elements/hotels/:mode/:id',
}

export const ROUTE_CATEGORIES = {
    element: <Pages.Categories />,
    path: '/elements/categories',
}

export const ROUTE_CATEGORIES_ = {
    element: <Pages.Categories />,
    path: '/elements/categories/:mode',
}

export const ROUTE_CATEGORIES__ = {
    element: <Pages.Categories />,
    path: '/elements/categories/:mode/:id',
}

export const ROUTE_SERVICES = {
    element: <Pages.Services />,
    path: '/elements/services',
}

export const ROUTE_SERVICES_ = {
    element: <Pages.Services />,
    path: '/elements/services/:mode',
}

export const ROUTE_SERVICES__ = {
    element: <Pages.Services />,
    path: '/elements/services/:mode/:id',
}

export const ROUTE_VISITS = {
    element: <Pages.Visits />,
    path: '/elements/visits',
}

export const ROUTE_VISITS_ = {
    element: <Pages.Visits />,
    path: '/elements/visits/:mode',
}

export const ROUTE_VISITS__ = {
    element: <Pages.Visits />,
    path: '/elements/visits/:mode/:id',
}

export const ROUTE_RESTAURANTS = {
    element: <Pages.Restaurants />,
    path: '/elements/restaurants',
}

export const ROUTE_RESTAURANTS_ = {
    element: <Pages.Restaurants />,
    path: '/elements/restaurants/:mode',
}

export const ROUTE_RESTAURANTS__ = {
    element: <Pages.Restaurants />,
    path: '/elements/restaurants/:mode/:id',
}

export const ROUTE_ACTIVITIES = {
    element: <Pages.Activities />,
    path: '/cms/activities',
}

export const ROUTE_ACTIVITIES_ = {
    element: <Pages.Activities />,
    path: '/cms/activities/:mode',
}

export const ROUTE_ACTIVITIES__ = {
    element: <Pages.Activities />,
    path: '/cms/activities/:mode/:id',
}

export const ROUTE_TOURS = {
    element: <Pages.Tours />,
    path: '/cms/tours',
}

export const ROUTE_TOURS_ = {
    element: <Pages.Tours />,
    path: '/cms/tours/:mode',
}

export const ROUTE_TOURS__ = {
    element: <Pages.Tours />,
    path: '/cms/tours/:mode/:id',
}

export const ROUTE_INNS = {
    element: <Pages.Inns />,
    path: '/cms/inns',
}

export const ROUTE_INNS_ = {
    element: <Pages.Inns />,
    path: '/cms/inns/:mode',
}

export const ROUTE_INNS__ = {
    element: <Pages.Inns />,
    path: '/cms/inns/:mode/:id',
}

export const ROUTE_BOOKING = {
    element: <Pages.Booking />,
    path: '/booking',
}

export const ROUTE_NOT_FOUND = {
    element: <Pages.NotFound />,
    path: '*'
}

export const generateUrl = (route, params = {}, search = null) => {
    let { path } = route;

    Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value);
    })

    if (search) {
        const searchParams = new URLSearchParams(search)
        path = `${path}?${searchParams.toString()}`
    }

    return path
}

export const routes = [
    ROUTE_LOADER,
    ROUTE_AUTH,
    ROUTE_AUTH_MODE,
    ROUTE_AUTH_MODE_ID,
    ROUTE_DASHBOARD,
    ROUTE_VEHICLES,
    ROUTE_VEHICLES_,
    ROUTE_VEHICLES__,
    ROUTE_CATEGORIES,
    ROUTE_CATEGORIES_,
    ROUTE_CATEGORIES__,
    ROUTE_PLACES,
    ROUTE_PLACES_,
    ROUTE_PLACES__,
    ROUTE_HOTELS,
    ROUTE_HOTELS_,
    ROUTE_HOTELS__,
    ROUTE_SERVICES,
    ROUTE_SERVICES_,
    ROUTE_SERVICES__,
    ROUTE_VISITS,
    ROUTE_VISITS_,
    ROUTE_VISITS__,
    ROUTE_RESTAURANTS,
    ROUTE_RESTAURANTS_,
    ROUTE_RESTAURANTS__,
    ROUTE_ACTIVITIES,
    ROUTE_ACTIVITIES_,
    ROUTE_ACTIVITIES__,
    ROUTE_TOURS,
    ROUTE_TOURS_,
    ROUTE_TOURS__,
    ROUTE_INNS,
    ROUTE_INNS_,
    ROUTE_INNS__,
    ROUTE_BOOKING,
    ROUTE_NOT_FOUND
]
