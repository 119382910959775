import { withStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import TuneIcon from '@mui/icons-material/Tune'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as AppMapping from '../../../actions/app'
import * as ToursMapping from '../../../actions/tours'
import * as JourneyMapping from '../../../actions/journey'

import width from '../../../services/theme/width'
import {generateUrl, ROUTE_TOURS_, ROUTE_TOURS__} from '../../../services/router'

import ComponentAbstract from '../../../components/ComponentAbstract'
import PageTitle from '../../../components/PageTitle'
import PageContainer from '../../../components/PageContainer'
import FlexRoot from '../../../components/FlexRoot'
import DrawerPanel from '../../../components/DrawerPanel'
import MenuItemIcon from '../../../components/MenuItemIcon'
import DataLoader from '../../../components/DataLoader'
import Tip from '../../../components/Tip'
import LinkTo from '../../../components/LinkTo'

import Editor from '../Editor'
import Wizard from '../Wizard'
import DeleteItem from '../DeleteItem'

import styles from './styles'
import Translation from './Translations'

class List extends ComponentAbstract {
    locale = 'FR'

    state = {
        panel: {
            editor: {display: false, title: ''},
            wizard: {display: false, title: ''}
        },
        dialog: {
            deleteItem: {display: false, title: ''}
        }
    }

    componentWillMount() {
        this.Translation = Translation
        this.locale = this.getLocale(true)
        this.props.actions.app.setSection(this.label('title'))
    }

    handleWizard = (mode, id = null) => {
        if (!this.state.panel.wizard.display) {
            if (mode === 'wizard' && id !== null) {
                const item = this.getRecord('tours', id)
                if (!item) return
                this.props.actions.tours.setCurrent(item, {success: this.loadJourney.bind(this)})
                const locale_title = this.label(`${mode}_title`)
                const labels = JSON.parse(item.label)
                this.handleOpenPanel('wizard', `${locale_title}: ${labels[this.locale]}`, 'bottom')
            }
        }
    }

    loadJourney = () => {
        this.props.actions.journey.load(this.props.states.tours.current.uuid)
    }

    handleEditor = (mode, id = null) => {
        if (!this.state.panel.editor.display) {
            let title = this.label(`${mode}_title`)
            if (mode === 'editor' && id !== null) {
                const item = this.getRecord('tours', id)
                if (!item) return
                this.props.actions.tours.setCurrent(item)
                const labels = JSON.parse(item.label)
                title = `${title}: ${labels[this.locale]}`
            }
            this.handleOpenPanel('editor', title, 'right')
        }
    }

    handleDelete = (uuid) => event => {
        this.props.actions.tours.setCurrent(uuid)
        this.handleOpenDialog('deleteItem', this.label('delete_item'), 'md')
    }

    transformLabel = (param) => {
        const labels = JSON.parse(param.row.label)

        return (
            <MenuItemIcon
                iconColWidth={3}
                textColWidth={9}
                iconBackgroundColor={'bgBody'}
                icon={'travel_explore'}
                label={labels[this.locale]}
            />
        )
    }

    transformCategories = (param) => {
        const categories = []
        const selectedCategories = param.row.categories || []
        const allCategories = this.props.states.categories.items

        for (let i = 0; i < selectedCategories.length; i++) {
            for (let j = 0; j < allCategories.length; j++) {
                if (selectedCategories[i] === allCategories[j].uuid) {
                    let labels = JSON.parse(allCategories[j].label);
                    categories.push(labels[this.locale])
                    break;
                }
            }
        }

        return (
            <Stack direction="row" spacing={1}>
                {categories && categories.map(category => {
                    return (<Chip size={'small'} color={'primary'} variant="outlined" label={category} />)
                })}
            </Stack>
        )
    }

    bindActions = (param) => {
        return (
            <Stack direction="row" alignItems="center" justifyItems={"center"} spacing={0}>
                <LinkTo to={generateUrl(ROUTE_TOURS__, {mode: 'wizard', id: param.row.uuid})}>
                    <IconButton color="primary" component="wizard">
                        <TuneIcon />
                    </IconButton>
                </LinkTo>
                <LinkTo to={generateUrl(ROUTE_TOURS__, {mode: 'edit', id: param.row.uuid})}>
                    <IconButton color="primary" component="edit">
                        <EditIcon />
                    </IconButton>
                </LinkTo>
                <IconButton color="danger" onClick={this.handleDelete(param.row.uuid)}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        )
    }

    handleSearch = (event, value, mode) => {
        this.redirectTo(ROUTE_TOURS__, {mode: 'edit', id: value.uuid})
    }

    render() {
        const {classes, width, states, action, id} = this.props

        if (action === 'add') this.handleEditor('creator')
        else if (action === 'edit') this.handleEditor('editor', id)
        else if (action === 'wizard') this.handleWizard('wizard', id)
        else this.checkPanelsCloseHandler(['editor', 'wizard'])

        let rowsPerPage = 10
        if (width === 'xs') rowsPerPage = 8
        if (width === 'sm') rowsPerPage = 14
        else if (width === 'md') rowsPerPage = 9
        else if (width === 'lg' || width === 'xl') rowsPerPage = 13

        const rows = states.tours.items ?? []
        const columns = [
            { field: 'label', headerName: this.label('name', 'column'), width: 300, renderCell: this.transformLabel },
            { field: 'categories', headerName: this.label('categories', 'column'), flex: (width === 'xs') ? 0 : 1, renderCell: this.transformCategories },
            { field: 'actions', headerName: this.label('actions', 'column'), width: 150, renderCell: this.bindActions}
        ]

        const searchField = (
            <Autocomplete
                className={classes.field}
                options={rows}
                blurOnSelect
                onChange={this.handleSearch}
                getOptionLabel={(row) => {
                    const labels = JSON.parse(row.label)
                    return labels[this.locale]
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={this.label('search_placeholder')}
                    />
                )}
            />
        )

        const addButton = (
            <LinkTo to={generateUrl(ROUTE_TOURS_, {mode: 'add'})}>
                    <Button variant="contained" endIcon={<TravelExploreIcon />}>
                    {this.label('add_cta')}
                </Button>
            </LinkTo>
        )

        const loader = states.app.loading.tours.items ?? 2

        return (
            <Fragment>
                <DeleteItem
                    config={this.state.dialog.deleteItem}
                    handleClose={this.handleCloseDialog('deleteItem')}
                />
                <DrawerPanel
                    config={this.state.panel.editor}
                    handleClose={this.handleClosePanels}
                    componentName={'editor'}
                >
                    <Editor mode={action} />
                </DrawerPanel>
                <DrawerPanel
                    config={this.state.panel.wizard}
                    handleClose={this.handleClosePanels}
                    componentName={'wizard'}
                    fullview
                >
                    <Wizard mode={action} />
                </DrawerPanel>
                <PageContainer>
                    {(loader === 0) ? (
                        <DataLoader label={this.label('loading_data', 'actions')} class={classes.loaderContainer} />
                    ) : ''}

                    {(loader === 2) ? (
                        <div className={classes.loaderContainer}>
                            <Tip
                                icon={'search_off'}
                                label={this.label('loader_error')}
                            />
                        </div>
                    ) : ''}

                    {(loader === 1) ? (
                        <Fragment>
                            <Grid container alignItems={'center'} justifyContent={'space-around'} className={classes.gridTopContainer}>
                                <Grid item xs={6} sm={4}>
                                    <PageTitle
                                        title={this.label('page_title')}
                                        subtitle={this.label('page_subtitle')}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {(width !== 'xs') ? searchField : addButton}
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.gridAddContainer}>
                                    {(width === 'xs') ? searchField : addButton}
                                </Grid>
                            </Grid>
                            {(rows.length < 1) ? (
                                <div className={classes.emptyContainer}>
                                    <Tip
                                        icon={'travel_explore'}
                                        label={this.label('empty_list')}
                                        linkLabel={this.label('add_cta')}
                                        type={'button'}
                                        link={{
                                            path: ROUTE_TOURS_,
                                            params: {mode: 'add'}
                                        }}
                                    />
                                </div>
                            ) : (
                                <FlexRoot>
                                    <div className={classes.gridContainer}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            getRowId={(row) => row.uuid}
                                            pageSize={rowsPerPage}
                                            rowsPerPageOptions={[rowsPerPage]}
                                        />
                                    </div>
                                </FlexRoot>
                            )}
                        </Fragment>
                    ) : ''}
                </PageContainer>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        states: state,
        width: width()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            app: bindActionCreators(AppMapping, dispatch),
            tours: bindActionCreators(ToursMapping, dispatch),
            journey: bindActionCreators(JourneyMapping, dispatch)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(List)
