const NONE_ = 'NONE_'

/**
 * @param index
 * @param state
 * @param action
 */
export function getCollection(index, state, action)
{
    return {
        ...state,
        [index]: action.data
    }
}

/**
 * @param state
 * @param action
 * @param index
 * @param section
 * @param value
 * @returns {*}
 */
export function setInMemory(state, action, index, section = null, value = null)
{
    let newState = state

    if (value) {
        newState = {
            ...state,
            [index]: {
                ...state[index],
                [section]: {
                    ...state[index][section],
                    [value]: action.data
                }
            }
        }
    } else if (!value && section) {
        newState = {
            ...state,
            [index]: {
                ...state[index],
                [section]: action.data
            }
        }
    } else if (!value && !section) {
        newState = {
            ...state,
            [index]: action.data
        }
    }

    return newState
}

/**
 * @param state
 * @param action
 * @returns {*}
 */
export function setAll(state, action)
{
    return action.data
}

/**
 * @param state
 * @param fields
 * @returns {*|{}}
 */
export function getUpdatedCurrent(state, fields) {
    let newCurrent = state || {}

    const keys_ = Object.keys(fields)
    for (let i = 0; i < keys_.length; i++)
        newCurrent[keys_[i]] = fields[keys_[i]]

    return newCurrent
}

/**
 * @param state
 * @param fields
 * @param id
 * @param getBy
 * @returns {[]}
 */
export function getUpdatedCollection(state, fields, id, getBy='uuid') {
    let newCollection = []

    for (let i = 0; i < state.length; i++)
        newCollection.push((state[i][getBy] === id)
            ? getUpdatedCurrent(state[i], fields)
            : state[i])

    return newCollection
}

/**
 * @param state
 * @param data
 * @returns {[]}
 */
export function addCollectionRecord(state, data)
{
    let newCollection = []

    for (let i = 0; i < state.length; i++) newCollection.push(state[i])

    newCollection.push(data)

    return newCollection
}

/**
 * @param result
 * @returns {{callback: null, type: string}}
 */
export function exception(result)  {
    if (result.callback && result.callback.error
        && (typeof(result.callback.error) === 'function'))
            result.callback.error(result)

    return {
        type: NONE_,
        callback: null
    }
}